@import "src/styles/colors";
@import "src/styles/text";

.score {
  position: relative;
  width: 116px;
  height: 116px;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid #D3D3D3;

  &-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 94px;
    height: 94px;
    border-radius: 50%;
    display: inline-block;
    border: 2px solid #D3D3D3;
    background: $color-snow-white;

    &-text {
      @include text-page-title;
      font-size: 36px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
    }

    &-edit {
      .input-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        input {
          width: 73px !important;
        }
      }
      &-percent {
        position: absolute;
        top: 36px;
        @include text-body;
        color: rgba($color-rich-black, 0.6);
      }
    }
  }
}