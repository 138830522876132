@import "src/styles/colors";
@import "src/styles/text";

.previous-request-text {
  border-radius: 40px;
  padding: 16px;
  color: #6B7280 !important;
  @include text-body;
}

.empty-documents {
  display: flex;
  justify-content: center;
  align-items: center;

  .empty-state {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;

    span {
      color: #6B7280 !important;
      font-size: 14px;
      margin-left: 30%;
      @include text-small-castoro;
    }

    button {
      margin-left: 20%;
    }
  }
}
.previous-requests {
  margin-top: 54px;

  h2 {
    @include text-small-subtitle;
    margin-bottom: 16px;
  }

  .requests-list {
    .request-header {
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) 160px;
      padding: 12px 24px;
      @include text-small;
      margin-bottom: 8px;

      span:last-child {
        text-align: right;
        padding-right: 56px;
      }
    }

    .request-item-container {
      border: 1px solid black;
      border-radius: 30px;
      margin-bottom: 12px;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .request-item {
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) 160px;
      padding: 16px 24px;
      align-items: center;
      background: white;
      @include text-md;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .status {
        color: $color-rich-black;
      }

      .more-detail-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        color: black;
        background: none;
        border: none;
        cursor: pointer;
        width: 100%;
        
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    .request-details {
      padding: 24px;
      background: white;

      .detail-row {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        .detail-label {
          @include text-small-subtitle;
          display: block;
          margin-bottom: 4px;
        }
      }

      .detail-actions {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}