@import "src/styles/colors";

.assessment-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 1px;
  padding-bottom: 25px;
  background: $color-snow-white;

  &-buttons {
    padding-left: 56px;
    padding-right: 56px;

    &-close {
      display: inline-block;
    }

    &-continue {
      display: inline-block;
      float: right;
    }
  }
}