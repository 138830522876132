@import "src/styles/text";
@import "src/styles/colors";

.expandable-text {
  margin-bottom: 12px;
  border-bottom: 1px solid rgba($color-rich-black, 0.5);

  &-title {
    @include text-small-subtitle;
    margin-bottom: 12px;
    position: relative;
    padding-right: 50px;
    cursor: pointer;

    &-icon {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &-content {
    @include text-body;
    margin-bottom: 8px;
  }
}