@import "src/styles/colors";
@import "src/styles/text";

.user-profile {
  height: 48px;
  display: inline-block;

  &-avatar {
    display: inline-block;
    width: 48px;
    height: 20px;
    margin-right: 16px;
    line-height: 23px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 50%;
    background: $color-rich-black;
    @include text-body;
    color: $color-snow-white;
    text-align: center;
  }

  &-text {
    display: inline-block;
    vertical-align: middle;

    &-name {
      @include text-body;
    }

    &-job {
      @include text-small;
    }
  }

  &-large {
    height: 90px;
    .user-profile {
      &-avatar {
        font-size: 45px;
        line-height: 50px;
        height: 53px;
        width: 89px;
        padding-top: 20px;
        padding-bottom: 17px;
        padding-left: 1px;
        color: $color-snow-white;
        margin-right: 24px;
      }

      &-text {
        position: relative;
        bottom: 8px;
        &-name {
          @include text-page-title;
        }
        &-job {
          @include text-small-subtitle;
        }
      }
    }
  }
}

.header {
  .user-profile {
    &-avatar {
      @media (max-width: 768px) {
        width: 38px;
        height: 10px;
        line-height: 12px !important;
        margin-right: 0px;
      }
    }

    &-text {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}