@import "src/styles/colors";
@import "src/styles/text";

.assessments-progress-bar {
  width: 100%;

  &-container {
    width: 100%;
    border-top: 1px solid rgba($color-rich-black, 0.5);
    border-bottom: 1px solid rgba($color-rich-black, 0.5);
    height: 7px;
    margin-bottom: 8px;

    &-fill {

      background: $color-rich-black;
      border-radius: 0 10px 10px 0;
      width: 0;
      height: 7px;
      transition: width .5s;
    }

    &-complited {
      border-radius: 0;
    }
  }

  &-text {
    @include text-small;
    text-align: center;
  }

}