@import "src/styles/colors";
@import "src/styles/text";

.marketplace-body {
  padding: 16px;

  &-search {
    margin-bottom: 40px;

    .input-container {
      width: 100%;

      input {
        width: -webkit-fill-available;
      }
    }
  }

  &-cards {
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
  }
}