@import "../../../../../styles/colors";

.resume {
  &-date {
    margin-bottom: 56px;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
    }

    .select-container {
      width: calc(25% - 14px) !important;
      margin-right: 14px !important;
      margin-bottom: 10px !important;

      @media (max-width: 768px) {
        width: 100% !important;
      }
  
      @media (max-width: 576px) {
        width: 100% !important;
      }
    }

    &-end {
      .select-container {
        margin-left: 14px !important;
        margin-right: 0 !important;

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          margin-left: 0 !important;
          width: 100% !important;
        }
    
        @media (max-width: 576px) {
          display: flex;
          flex-direction: column;
          margin-left: 0 !important;
          width: 100% !important;
        }
      }
    }
  }
}