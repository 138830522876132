@import "src/styles/text";

.create-admin {
  position: absolute;
  width: 343px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &-container {
    position: relative;
    height: 80vh;
  }

  &-title {
    @include text-page-title;
    margin-bottom: 24px;
  }

  .input-container {
    margin-bottom: 24px;
  }

  &-submit {
    .btn {
      width: 100%;
    }
  }
}