@import "../../../../styles/colors";
@import "../../../../styles/text";

.assessment-answer {
  @include text-body;
  width: 600px;
  padding: 20px 24px;
  border-radius: 100px;
  border: 1px solid rgba($color-rich-black, 0.5);
  margin-top: 24px;
  margin-bottom: 24px;
  cursor: pointer;

  &-image {
    height: 85px;
  }

  &:hover {
    background: rgba($color-gold, 0.2);
  }

  &-chosen {
    background: $color-gold;

    &:hover {
      background: $color-gold;
    }
  }
}