@import "../../../styles/colors";
@import "../../../styles/text";

.dark-dropzone-container {
  background-color: #0f0f0f;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  @media (max-width: 768px) {
    padding-top: 10px;
  }
  @media (max-width: 576px) {
    padding-top: 5px;
  }
}

.upload-box {
  background-color: #0f0f0f;
  padding: 3rem;
  width: 55rem;
  text-align: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 80%;
    padding: 2rem;
  }

  @media (max-width: 576px) {
    width: 90%;
    padding: 1.5rem;
  }

  h1 {
    @include text-subtitle;
    color: $color-snow-white;
    font-size: 2rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      position: absolute;
      margin-left: 370px;
      margin-top: 50px;
      transform: rotate(10deg);
      
      @media (max-width: 768px) {
        margin-left: 200px;
        margin-top: 30px;
        height: 38px;
      }

      @media (max-width: 576px) {
        display: inherit;
        margin-left: 230px;
        margin-top: 35px;
        transform: rotate(10deg);
      }
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }

    @media (max-width: 576px) {
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
    }
  }

  &-upload-area {
    background-color: #0f0f0f;
    padding: 8rem 12rem;
    border-radius: 40px;
    border: 1px solid #666;
    color: #fff;
    text-align: center;

    @media (max-width: 768px) {
      padding: 5rem 6rem;
    }

    @media (max-width: 576px) {
      padding: 3rem 2rem;
      border-radius: 20px;
    }

    &-upload-label {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;
      border: none;

      &-upload-icon {
        background-color: #ffb700;
        border-radius: 50%;
        padding: 1rem;
        color: #fff;
        margin-bottom: 1rem;
        font-size: 2rem;
        cursor: pointer;

        @media (max-width: 768px) {
          padding: 0.75rem;
          font-size: 1.5rem;
        }

        @media (max-width: 576px) {
          padding: 0.5rem;
          font-size: 1.25rem;
        }
      }

      &-upload-instructions {
        @include text-subtitle;
        color: $color-snow-white;
        margin-bottom: 1.5rem;

        @media (max-width: 768px) {
          font-size: 1rem;
        }

        @media (max-width: 576px) {
          font-size: 0.9rem;
        }
      }

      input[type="file"] {
        display: none;
      }
    }

    &-upload-instructions {
      @include text-body;
      color: $color-snow-white;

      @media (max-width: 768px) {
        font-size: 0.9rem;
      }

      @media (max-width: 576px) {
        font-size: 0.8rem;
      }
    }
  }

  &-or-separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 2.5rem 0;
    color: #fff;
    padding: 0 6rem;

    @media (max-width: 768px) {
      padding: 0 4rem;
    }

    @media (max-width: 576px) {
      padding: 0 2rem;
      margin: 1.5rem 0;
    }

    &::before,
    &::after {
      content: "";
      flex: 1;
      border-bottom: 1px solid #666;
    }

    &:before {
      margin-right: 0.75em;
    }

    &:after {
      margin-left: 0.75em;
    }
  }

  &-manual-entry-link {
    @include text-md;
    color: $color-snow-white;
    text-decoration: none;
    margin-top: 1rem;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 2rem;

    span {
      text-decoration: underline;
      color: #ffb700;
    }

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }

    @media (max-width: 576px) {
      font-size: 0.8rem;
    }
  }

  &-linkedin-text {
    @include text-md;
    color: $color-snow-white;

    .linkedin-link {
      text-decoration: underline;
      color: #ffb700;
      cursor: pointer;
        
        &:hover {
            text-decoration: none;
        }
    }
}

  &-uploaded-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    color: #000;
    margin-bottom: 1rem;

    .file-name {
      font-size: 0.9rem;
      flex-grow: 1;
      margin-left: 1rem;

      @media (max-width: 768px) {
        font-size: 0.8rem;
      }

      @media (max-width: 576px) {
        font-size: 0.7rem;
      }
    }

    .remove-file {
      color: #666;
      cursor: pointer;
      margin-left: 10px;

      @media (max-width: 576px) {
        font-size: 0.9rem;
      }
    }
  }

  &-checkbox-group {
    padding: 2rem 1rem 2rem 5rem;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include text-small;
    color: $color-snow-white;

    @media (max-width: 768px) {
      padding: 1rem;
      gap: 15px;
    }

    @media (max-width: 576px) {
      padding: 0.5rem;
      gap: 10px;
    }

    a {
      text-decoration: underline;
      color: #ffb700;
    }

    .custom-checkbox-label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      @media (max-width: 576px) {
        font-size: 0.9rem;
      }
    }

    .custom-checkbox {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border: 2px solid white;
      appearance: none;
      background-color: transparent;
      cursor: pointer;
      position: relative;

      @media (max-width: 768px) {
        width: 16px;
        height: 16px;
      }

      @media (max-width: 576px) {
        width: 14px;
        height: 14px;
      }
    }

    .custom-checkbox:checked::before {
      content: "\2713"; /* Checkmark symbol */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: white;

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
  }

  &-upload-button {
    margin-top: 24px;
    background-color: #ffb700;
    color: $color-rich-black;

    @media (max-width: 768px) {
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
    }

    @media (max-width: 576px) {
      font-size: 0.8rem;
      padding: 0.4rem 0.8rem;
    }
  }
}