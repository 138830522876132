@import "src/styles/text";
@import "src/styles/colors";

.info {
  display: inline-block;
  position: relative;

  &-icon {
    cursor: pointer;
    vertical-align: middle;

    &:hover + .info-text {
      display: inline-block !important;
    }
  }

  &-text {
    @include text-body;
    color: $color-snow-white;
    padding: 23px 18px 18px 18px;
    display: none;
    position: absolute;
    top: 36px;
    background-image: url("../../../assets/icons/UI/icons/info-bg.svg");
    background-repeat: no-repeat;
    width: 435px;
    background-size: cover;
    //height: 192px;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
  }
}