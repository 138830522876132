.employees-table {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .search-and-sort {
    margin-bottom: 20px;
  }

  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .table-container {
    min-height: 68vh;
  }
}
