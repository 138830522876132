@import "src/styles/colors";
@import "src/styles/text";

.applicant-info-documents-section {
  padding: 24px 30px 24px 0px;

  &-download-all {
    color: $color-rich-black;
    text-decoration: underline;
    cursor: pointer;
    @include text-small-subtitle;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .info-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    
    .info-header {
      display: flex;
      align-items: center;
      gap: 12px;
      
      h2 {
        font-size: 18px;
        font-weight: 600;
        color: #1a1a1a;
        margin: 0;
      }
    }
  }

  .documents-list {
    .documents-header {
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) 160px;
      @include text-small;
      color: #666;
      
      span:last-child {
        text-align: right;
      }
    }

    .document-item-container {
      border-bottom: 1px dashed #979797;
      padding: 16px 0px;
      
      &:last-child {
        border-bottom: none;
      }
    }

    .document-item {
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) 160px;
      align-items: center;
      @include text-md;

      &-title {
          @include text-small-bold;
      }

      span {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #1a1a1a;
        
        &:first-child {
          font-weight: 500;
        }
      }

      &-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;

        .toggle-icon {
          cursor: pointer;
          color: #666;
        }
      }

      &-download {
        background: #FFD500;
        padding: 8px 16px;
        border-radius: 20px;
        border: 1px solid black;
        color: #1a1a1a;
        cursor: pointer;
        font-weight: 500;
        text-decoration: none;
        font-size: 14px;
        text-align: center;
        
        &:hover {
          background: #FCD34D;
        }
      }
    }

    .request-details {
      margin-top: 12px;
      padding: 16px;
      background: #f9f9f9;
      border-radius: 8px;

      .detail-row {
        margin-bottom: 12px;
        
        &:last-child {
          margin-bottom: 0;
        }

        .detail-label {
          @include text-small-subtitle;
          color: #666;
          display: block;
          margin-bottom: 4px;
        }
        
        .detail-value {
          color: #1a1a1a;
        }
      }
    }
  }
}