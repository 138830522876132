@import "src/styles/colors";
@import "src/styles/text";

.icon-chiclet {
  display: inline-block;
  padding: 5px 11px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid $color-rich-black;
  background: #B8DDDD;
  vertical-align: top;

  &-image {
    margin-right: 4px;
    vertical-align: text-bottom;
  }

  &-text {
    display: inline-block;
    vertical-align: bottom;
    @include text-small;
  }
}