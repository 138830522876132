@import '../../../styles/colors';
@import '../../../styles/text';
@import '../../../styles/resolution';

.page-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 80px);
    padding: 20px;
    text-align: center;
    max-width: 480px;
    margin: 0 auto;

    &-subtitle {
        @include text-page-title;
        margin-bottom: 24px;
        font-weight: 600
    }

    &-message {
        @include text-body;
        margin-bottom: 32px;
        line-height: 1.5;
    }

    &-action {
        button {
            min-width: 160px;
            
            @media (max-width: $breakpoint-mobile) {
                width: 100%;
            }
        }
    }

    @media (max-width: $breakpoint-mobile) {
        padding: 16px;
        min-height: calc(100vh - 64px);
    }
}