@import "src/styles/text";
@import "src/styles/colors";

.evidence-button {
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #81B69A;
  background: #DEF3E8;
  height: 84px;
  padding: 24px;


  &-icon {
    vertical-align: middle;
    margin-right: 12px;
  }

  &-text {
    font-family: "Avenir Next", monospace;
    font-size: 16px;
    font-weight: 600;
    color: $color-rich-black;
  }

  &-reject {
    border: 1px solid #BE8F8F;
    background: #FFF2F2;
  }

  &-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

}