@import "../../../styles/colors";
@import "../../../styles/text";

.edit-resume {
  position: relative;
  min-height: 100vh;

  &-upload-dropzone {
    margin: -24px;

    @media (max-width: 768px) {
      margin: -16px;
    }

    @media (max-width: 576px) {
      margin: -8px;
    }
  }

  &-faq {
    padding: 70px 180px;

    @media (max-width: 768px) {
      padding: 40px 80px;
    }

    @media (max-width: 576px) {
      padding: 20px 40px;
    }
  }

  &-applicant-subtitle {
    @include text-page-small-title;
    margin-top: 29px;

    @media (max-width: 768px) {
      font-size: 1.25rem;
      margin-top: 20px;
    }

    @media (max-width: 576px) {
      font-size: 1rem;
      margin-top: 15px;
    }
  }

  &-applicant-small-title {
    @include text-subtitle;
    margin-top: 29px;

    @media (max-width: 768px) {
      font-size: 1.25rem;
      margin-top: 20px;
    }

    @media (max-width: 576px) {
      font-size: 1rem;
      margin-top: 15px;
    }
  }

  &-applicant-mandatory {
    @include text-body-italic;
    margin-top: 29px;
    margin-bottom: 29px;

    @media (max-width: 768px) {
      font-size: 0.9rem;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @media (max-width: 576px) {
      font-size: 0.8rem;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  &-my-profile-heading {
    @include text-body;
    margin-top: 29px;

    @media (max-width: 768px) {
      font-size: 1rem;
      margin-top: 20px;
    }

    @media (max-width: 576px) {
      font-size: 0.9rem;
      margin-top: 15px;
    }
  }

  &-title {
    @include text-page-title;
    margin-bottom: 8px;

    @media (max-width: 768px) {
      font-size: 1.75rem;
      margin-bottom: 6px;
    }

    @media (max-width: 576px) {
      font-size: 1.5rem;
      margin-bottom: 4px;
    }
  }

  &-submit {
    margin-top: 56px;

    @media (max-width: 768px) {
      margin-top: 40px;
    }

    @media (max-width: 576px) {
      margin-top: 30px;
    }
  }

  &-bottom-navigation {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    @media (max-width: 768px) {
      gap: 8px;
    }

    @media (max-width: 576px) {
      gap: 6px;
      justify-content: center;
    }
  }

  &-add-padding {
    padding: 20px 130px;

    @media (max-width: 768px) {
      padding: 15px 80px;
    }

    @media (max-width: 576px) {
      padding: 10px 20px;
    }
  }

  &-add-padding-bottom-navigation {
    padding-right: 130px;
    padding-bottom: 50px;

    @media (max-width: 768px) {
      padding-right: 80px;
      padding-bottom: 30px;
    }

    @media (max-width: 576px) {
      padding-right: 20px;
      padding-bottom: 20px;
    }
  }
}
