@import "src/styles/colors";
@import "src/styles/text";

.evidence-dropzone {
    &-submission {
        margin-bottom: 30px;
        margin-top: 30px;
    }
    &-request {
        display: flex;
        flex-direction: row;
        gap: 5px;
        &-title {
            @include text-small-subtitle;
        }
    }
    &-note {
        margin-top: 50px;
        margin-bottom: 10px;
    }
    &-divider {
        margin: 30px 0px;
        text-align: center;
        display: flex;
        align-items: center;
        text-align: center;
        color: #aaa;
        padding: 0 25rem;

        &::before,
        &::after {
            content: "";
            flex: 1;
            border-bottom: 1px solid #aaa;
        }

        &:before {
            margin-right: 0.75em;
        }

        &:after {
            margin-left: 0.75em;
        }
    }
    &-browse {
        width: 100%;
        text-align: center;
        span {
            color: $color-gold;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .dropzone-container {
      position: relative;
      width: 100%;
      min-height: 310px;
      // border: 2px dashed #ddd;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23AAAAAA' stroke-width='4' stroke-dasharray='14, 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

      border-radius: 30px;
      transition: border-color 0.3s ease;

      &:hover {
        border-color: #aaa;
      }

      .dropzone-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 20px;
        text-align: center;

        .dropzone-icon {
          &-svg {
            background-color: #ffb700;
            border-radius: 50%;
            padding: 1rem;
            color: #fff;
            margin-bottom: 1rem;
            font-size: 2rem;
            cursor: pointer;
          }
        }

        .dropzone-title {
          @include text-subtitle;
          margin-bottom: 16px;
          color: #333;
        }

        .dropzone-text {
          @include text-body;
          margin-bottom: 30px;
          line-height: 1.5;
        }

        .file-info {
          display: flex;
          align-items: center;
          padding: 12px 16px;
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 10px;
          margin-bottom: 20px;
          min-width: 300px;

          .file-icon {
            margin-right: 8px;
          }

          .file-name {
            flex: 1;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .file-remove {
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }