@import "../../../../../styles/colors";
@import "../../../../../styles/text";

.work-experience {
  position: relative;
  @include text-body;

  .textarea {
    textarea {
      width: calc(100% - 32px) !important;
      margin-bottom: 24px;
    }
  }

  .input-container {
    input, textarea {
      width: calc(100% - 32px) !important;
      margin-bottom: 24px;
    }
  }

  &-close {
    position: absolute;
    top: -16px;
    right: 16px;

    &-delete-icon {
      width: 75px;
      @include text-small;
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;

      &-disabled {
        color: rgba($color-rich-black, 0.5);
        cursor: not-allowed;
      }
    }
  }

  &-description {

    .textarea textarea {
      height: 200px !important;
    }
  }

  &-normalization {
    @include text-body;
    padding-left: 24px;
    height: 64px;
    width: calc(100% - 24px);
    border-radius: 30px;
    border: 1px solid #212121;
    line-height: 64px;
    margin-bottom: 24px;

    img {
      margin-right: 16px;
      vertical-align: middle;
      cursor: pointer;
      position: relative;
      top: -1px;
    }
  }

  &-notification {
    margin-bottom: 24px;
    padding: 8px 16px 8px 16px;
    background: #FCF1CC;
    border: 1px solid #212122;
    border-radius: 10px;
    @include text-body;

    svg {
      margin-right: 6px;
      vertical-align: top;
    }

    &-error {
      background: rgba($color-error-red, .1);
      border: 1px solid $color-error-red;
    }
  }

  textarea {
    white-space: pre-wrap;
    resize: none;
  }

  .select-container {
    width: 100%;
    margin-bottom: 24px;

    @media (max-width: 768px) {
      width: 100% !important;
    }

    @media (max-width: 576px) {
      width: 100% !important;
    }

    .selectkmq__control {
      width: 100%;
    }
  }
}