@import "src/styles/colors";
@import "src/styles/text";

.breadcrumbs {
  margin-bottom: 24px;

  &-option {
    display: inline-block;

    &-text {
      display: inline-block;
      @include text-small;
      color: rgba($color-rich-black, 0.5);
      margin-right: 8px;
      cursor: pointer;
    }

    &-icon {
      margin-right: 8px;
      vertical-align: middle;
    }

    &-current {
      .breadcrumbs-option-text {
        color: $color-rich-black;
        cursor: initial;
      }

      img {
        display: none;
      }
    }
  }
}