@import "src/styles/text";
@import "src/styles/colors";

.login-form {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &-title {
    @include text-page-title;
  }

  &-error {
    color: $color-error-red;
    display: flex;
    gap: 3px;
  }

  &-password-wrap {
    position: relative;

    &-link {
      @include text-small;
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      z-index: 1;
    }
  }

  .input-container {
    margin-top: 24px;
  }

  &-sign-up {
    @include text-small;
    margin-top: 8px;
    margin-bottom: 24px;

    &-link {
      cursor: pointer;
      font-weight: bold;
    }
  }

  &-footer {
    .btn {
      width: 100%;
    }
  }
}