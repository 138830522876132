@import "src/styles/colors";
@import "src/styles/text";

.password-requirements {
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 20px;
  border: 1px solid rgba($color-rich-black, 0.5);

  &-title {
    @include text-body;
  }

  &-check {
    margin-top: 12px;
  }
}