@import "src/styles/text";

.login-verification {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 343px;
  padding: 16px;
  
  &-title {
    @include text-page-title;
    margin-bottom: 24px;
  }

  &-text {
    @include text-body;
    margin-bottom: 24px;
  }

  &-code {
    margin-bottom: 24px;
    display: flex;
  }

  &-footer {
    .btn {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 16px;
    top: 30%;
    transform: translate(0%, 0%) !important;
    left: 0% !important;

    &-title {
      font-size: 1.5rem;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 1rem;
      margin-bottom: 16px;
    }

    &-code {
      margin-bottom: 16px;
    }

    &-footer {
      margin-top: 16px;

      .btn {
        padding: 12px 0;
      }
    }
  }
}
