@import "src/styles/colors";
@import "src/styles/text";
@import "src/styles/cards";
@import "src/styles/resolution";
.result-card {
  @include regular-card;
  padding: 24px;
  width: unset;
  min-width: 342px;
  display: inline-block;

  &-grading {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    align-items: center;

    
    .assessment-results-grade-large {
      display: flex;
    }
    .assessment-results-combination {
      
      &-adaptabilityassessment {
        position: relative;
        top: -30px;
        width: 300px;
        display: flex;
        justify-content: start;
        align-items: center;

        .assessment-results-h3 {
          @include text-body;
          margin-left: 8px;
        }
      }
      
      &-adaptabilityassessment:nth-child(2) {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-left: 15px;
      }
      
      &-adaptabilityassessment:nth-child(3) {
        position: relative;
        display: flex;
        justify-content: start;
        align-items: center;

      }
      &-adaptabilityassessment:nth-child(4) {
        position: relative;
        display: flex;
        justify-content: start;
        align-items: center;
      }
      &-adaptabilityassessment:nth-child(5) {
        position: relative;
        display: flex;
        justify-content: start;
        align-items: center;
        left: 130px;
        top: -50px;
      }

      &-adaptabilityassessment:nth-child(6) {
        position: relative;
        display: flex;
        justify-content: start;
        align-items: center;
        left: 130px;
        top: -50px;
      }

      &-adaptabilityassessment:nth-child(7) {
        position: relative;
        display: flex;
        justify-content: start;
        align-items: center;
        left: 130px;
        top: -50px;
      }

      &-adaptabilityassessment:nth-child(8) {
        position: relative;
        display: flex;
        justify-content: start;
        align-items: center;
        left: 130px;
        top: -40px;
      }
    }
    .assessment-results-dropdown{
      display: flex;
      margin-bottom: 8px;

      &-grade {
        margin-right: 8px;
      }

      &-h3{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        @include text-body;
      }

      &-acer{
        display: flex;
        margin-bottom: 8px;
        width: 587px;

        &-grade {
          margin-right: 8px;
        }
  
        &-h3{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          @include text-body;
        }
      }

      &-ocean{
        display: flex;
        justify-content: start;
        width: 391px;
        margin-bottom: 8px;
  
        &-grade {
          margin-right: 8px;
        }
  
        &-h3{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          @include text-body;
        }
      }

      &-emotionalintelligence {
        display: flex;
        width: 30%;
        margin-right: 8px;
      }
    }



  }
  &-title {
    width: unset;
    display: inline-block;
    position: relative;
    margin-bottom: 33px;

    &-bottom {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      border-radius: 1px;
      background: $color-rich-black;
    }
  }

  &-grading {
    margin-bottom: 32px;

    &-total {
      display: inline-block;
      vertical-align: top;
      margin-right: 40px;
    }

    &-all {
      display: inline-table;
      border-spacing: 0;

      td {
        padding: 0;
      }

      tr:first-child {
        .result-card-grading-all-item {
          margin-bottom: 8px;
        }
      }

      &-item {
        margin-right: 36px;
        display: inline-block;

        &-text {
          margin-left: 8px;
        }
      }
    }
  }

  &-large {
    width: 1174px;

    .result-card {
      &-footer {
        text-align: right;
      }
    }
  }


}

@media (max-width: $breakpoint-13inch) {

  .result-card{

    &-grading {
      .assessment-results-combination {
        &-adaptabilityassessment{
          width: 250px;
        }
      }
    }

    &-large {
      width: 900px;
    }
  }
}