@import "src/styles/text";

.applicant-program-layout {
  .back {
    margin-bottom: 24px;
  }

  &-title {
    @include text-page-title;
    margin-bottom: 4px;
  }

  &-courses {
    @include text-small;
    margin-bottom: 24px;
  }

  .navigation-tabs {
    margin-bottom: 24px;
  }
}