@import "src/styles/colors";
@import "src/styles/text";

.toggle {
  display: inline-block;
  width: max-content;

  &-label {
    @include text-body;
    margin-right: 8px;
  }

  &-container {
    width: 42px;
    height: 22px;
    display: inline-block;
    background: rgba($color-rich-black, 0.5);
    vertical-align: middle;
    border-radius: 11px;
    position: relative;
    cursor: pointer;
    transition: all .1s linear;

    &-circle {
      border-radius: 50%;
      width: 18px;
      height: 18px;
      display: inline-block;
      position: absolute;
      top: 2px;
      left: 2px;
      background: $color-snow-white;
      transition: all .1s linear;
    }

    &-active {
      background: $color-rich-black;

      .toggle-container-circle {
        left: 22px;
      }
    }

    &-disabled {
      background: rgba($color-rich-black, 0.2);
      cursor: not-allowed;
      
      .toggle-container-circle {
        background: rgba($color-snow-white, 0.5);
      }
    }
  }

  &-label-disabled {
    color: grey;
  }
}
