.checkbox-filter {
    --border-color: rgba(17, 24, 39, 0.5);
    --text-color: #111827;
    --hover-color: #F9FAFB;
    position: relative;
    display: inline-block;
    vertical-align: middle;
  
    .filter-button {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px 12px;
      background: white;
      border: none;
      border-radius: 6px;
      color: var(--text-color);
      font-size: 16px;
      font-family: Castoro, monospace;
      cursor: pointer;
  
      .filter-icon {
        margin-right: 8px;
      }
  
      .filter-text {
        flex: 1;
        margin-right: 16px;
      }
  
      .arrow-icon {
        width: 16px;
        height: 16px;
      }
    }
  
    .filter-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 8px;
      width: 230px;
      padding: 24px;
      background: white;
      border: 1px solid var(--border-color);
      border-radius: 30px;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
      z-index: 10;
  
      .filter-options {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
  
      .checkbox-option {
        display: flex;
        align-items: center;
        cursor: pointer;
  
        .checkbox-input {
          position: relative;
          width: 18px;
          height: 18px;
          margin-right: 12px;
          border: 2px solid var(--border-color);
          border-radius: 4px;
          appearance: none;
          cursor: pointer;
          background-color: white;
  
          &:checked {
            background-color: white;
            border-color: var(--border-color);
  
            &::after {
              content: '';
              position: absolute;
              left: 50%;
              top: 45%;
              width: 5px;
              height: 10px;
              border: solid var(--text-color);
              border-width: 0 2px 2px 0;
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }
        }
  
        .checkbox-label {
          color: var(--text-color);
          font-size: 16px;
          font-family: Castoro, monospace;
        }
      }
  
      .filter-actions {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 8px;
  
        .reset-button {
          color: rgba(17, 24, 39, 0.6);
          text-decoration: underline;
          background: none;
          border: none;
          cursor: pointer;
          font-size: 14px;
          padding: 0;
          align-self: flex-start;
        }
  
        .action-buttons {
          display: flex;
          gap: 8px;
          
          button {
            min-width: 80px;
          }
        }
      }
    }
  }