@import "src/styles/colors";
@import "src/styles/text";

.instruction-modal {
  .ReactModal {
    &__Content {
      width: 698px;
      border-radius: 20px !important;
      border: 1px solid rgba($color-rich-black, 0.5) !important;
      background: $color-snow-white !important;
      box-shadow: 0 2px 2px 0 rgba(33, 33, 34, 0.20) !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
      padding: 0 !important;
      height: auto !important;
      max-height: fit-content !important;
    }

    &__Overlay {
      background-color: rgba($color-rich-black, 0.5) !important;
      z-index: 10000;
    }
  }

  &-body {
    position: relative;
    padding: 24px;

    &-close {
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;
    }

    &-icon {
      margin-bottom: 16px;
      height: 40px;
    }

    &-title {
      @include text-page-title;
      margin-bottom: 8px;
    }

    &-icons {
      margin-bottom: 24px;
    }

    &-description {
      @include text-body;
      padding-left: 16px;

      li {
        margin-bottom: 8px;
      }
    }

    &-footer {
      margin-top: 56px;
      text-align: right;
    }
  }
}