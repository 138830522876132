@import "src/styles/v2/fonts/fonts";
@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

@mixin body-text {
  font-family: 'Montreal Regular', serif;
  line-height: 1.5;
  letter-spacing: 0;
  font-weight: 400;
}

@mixin text-v2 {
  h1 {
    font-family: 'Montreal Semi-bold', serif;
    font-size: 4rem;
    line-height: 1.1;
    letter-spacing: -0.025em;
    //margin: 0;

    @media (max-width: $breakpoint-tablet) {
      font-size: 3rem;
      line-height: 1.2;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 2.25rem;
    }
  }

  h2 {
    font-family: 'Montreal Semi-bold', serif;
    font-size: 3rem;
    line-height: 1.2;
    letter-spacing: -0.025em;
    //margin: 0;

    @media (max-width: $breakpoint-tablet) {
      font-size: 2.5rem;
      letter-spacing: -0.02em;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 2rem;
    }
  }

  h3 {
    font-family: 'Montreal Semi-bold', serif;
    font-size: 2.25rem;
    line-height: 1.3;
    letter-spacing: -0.015em;
    //margin: 0;

    @media (max-width: $breakpoint-tablet) {
      font-size: 2rem;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 1.5rem;
    }
  }

  h4 {
    font-family: 'Montreal Semi-bold', serif;
    font-size: 1.5rem;
    line-height: 1.3;
    letter-spacing: -0.015em;
    //margin: 0;

    @media (max-width: $breakpoint-mobile) {
      font-size: 1rem;
      letter-spacing: 0;
    }
  }

  h5 {
    font-family: 'Montreal Semi-bold', serif;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: -0.01em;
    //margin: 0;

    @media (max-width: $breakpoint-mobile) {
      font-size: 0.875rem;
      line-height: 1.5;
      letter-spacing: 0;
    }
  }

  h6 {
    font-family: 'Montreal Medium', serif;
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: 0;
    //margin: 0;
    font-weight: 500;

    @media (max-width: $breakpoint-mobile) {
      font-size: 0.875rem;
    }
  }

  .btn-v2 {
    font-family: 'Montreal Semi-bold', serif;
    font-size: 1.25rem;
    line-height: 1.3;
    letter-spacing: 0;

    @media (max-width: $breakpoint-mobile) {
      font-size: 1.125rem;
    }

    &-regular {
      font-family: 'Montreal Semi-bold', serif;
      font-size: 1rem;
      line-height: 1.3;
      letter-spacing: 0;

      @media (max-width: $breakpoint-mobile) {
        font-size: 0.9375rem;
      }
    }
  }

  .caption {
    display: inline-block;
    font-family: 'Montreal Medium', serif;
    font-size: 1rem;
    line-height: 1.3;
    letter-spacing: 0;
    // margin: 0;
    font-weight: 500;

    @media (max-width: $breakpoint-tablet) {
      font-size: 0.9375rem;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 0.8125rem;
    }

    &-small {
      font-size: 0.875rem;

      @media (max-width: $breakpoint-mobile) {
        font-size: 0.75rem;
      }

      &-light {
        font-family: 'Montreal Regular', serif;
      }
    }
  }

  .text-body {
    &-xl {
      @include body-text;
      line-height: 1.3;
      font-size: 3rem;

      @media (max-width: $breakpoint-tablet) {
        font-size: 2rem;
        line-height: 1.5;
      }

      @media (max-width: $breakpoint-mobile) {
        font-size: 1.5rem;
      }
    }

    &-large {
      @include body-text;
      font-size: 2.25rem;

      @media (max-width: $breakpoint-tablet) {
        font-size: 1.5rem;
      }

      @media (max-width: $breakpoint-mobile) {
        font-size: 1rem;
      }
    }

    &-regular {
      @include body-text;
      font-size: 1.5rem;

      @media (max-width: $breakpoint-tablet) {
        font-size: 1.25rem;
      }

      @media (max-width: $breakpoint-mobile) {
        font-size: 1rem;
      }
    }

    &-small {
      @include body-text;
      font-size: 1.25rem;

      @media (max-width: $breakpoint-tablet) {
        font-size: 1.125rem;
      }

      @media (max-width: $breakpoint-mobile) {
        font-size: 0.875rem;
      }
    }

    &-xs {
      @include body-text;
      font-size: 1.125rem;

      @media (max-width: $breakpoint-tablet) {
        font-size: 1rem;
      }

      @media (max-width: $breakpoint-mobile) {
        font-size: 0.875rem;
      }
    }

    &-xxs {
      @include body-text;
      font-size: 1rem;

      @media (max-width: $breakpoint-tablet) {
        font-size: 0.875rem;
      }
    }
  }

  .text-label {
    font-family: 'Montreal Semi-bold', serif;
    font-size: 1.5rem;
    line-height: 1.3;
    letter-spacing: 0;
    text-transform: uppercase;
    font-weight: 600;

    @media (max-width: $breakpoint-tablet) {
      font-size: 1.25rem;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 0.875rem;
    }
  }
}