@import "src/styles/header";
@import "src/styles/colors";
@import "src/styles/resolution";

.soft-user-header {
  @include header;
  position: relative;
  border-bottom: 1px $color-rich-black solid;

  @media (max-width: $breakpoint-13inch) {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: $breakpoint-mobile) {
    width: calc(100vw - 40px);
  }

  .header-logo {
    @media (max-width: $breakpoint-13inch) {
      width: 200px;
    }
  }

  &-logo-fleming {
    cursor: pointer;
    width: 171px;
    position: absolute;
    left: 5px;
    top: 5px;
  }

  &-button {
    position: absolute;
    right: 40px;

    @media (max-width: $breakpoint-mobile) {
      right: 4px;
    }
  }
}