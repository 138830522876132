@import "src/styles/colors";
@import "src/styles/text";

.plar-card-status {
  position: absolute;
  right: 24px;
  top: 24px;
  text-align: right;

  .progress-chiclet-container {
    margin-left: 16px;
  }

  &-text {
    @include text-small;
    margin-bottom: 8px;
  }
}