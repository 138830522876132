.save-status {
  display: inline-flex;
  align-items: center;
  gap: 1px;

  .save-icon {
    position: relative;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;

    .file-base {
      .icon-path {
        fill: #000000;
        transition: fill 0.2s ease;
      }
    }
  }

  &.initial {
    .save-icon .file-base .icon-path {
      fill: #999999;
    }
    .status-text {
      color: #999999;
    }
  }

  &.saving {
    .save-icon .file-base .icon-path {
      fill: #666666;
    }
    .status-text {
      color: #666666;
    }
  }

  &.saved {
    .save-icon .file-base .icon-path {
      fill: #000000;
    }
    .status-text {
      color: #000000;
    }
  }

  .status-text {
    font-size: 14px;
    line-height: 1;
    transition: color 0.2s ease;
  }
}