@import "src/styles/colors";
@import "src/styles/text";

.portfolio-chiclet {
  display: inline-block;
  padding: 5px 11px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid $color-rich-black;
  margin-bottom: 16px;
  @include text-small;

  &-not_created {
    display: none;
  }

  &-pending_payment {
    border: 1px dashed $color-dark-yellow;
    background: rgba($color-dark-yellow, 0.2);
  }

  &-review_pending {
    border: 1px solid $color-dark-yellow;
    background: rgba($color-dark-yellow, 0.2);
  }

  &-review_in_progress {
    background: $color-dark-yellow;
  }

  &-review_completed {
    background: $color-green;
  }
}