@import "./fonts/fonts";
@import "src/styles/colors";
@import "src/styles/resolution";

@mixin text-page-title {
  font-family: Castoro, monospace;
  font-size: 40px;
  line-height: 53px;
  color: $color-rich-black;

  @media (max-width: $breakpoint-mobile) {
    font-size: 24px;
    line-height: 30px;
  }
}

@mixin text-page-small-title {
  font-family: Castoro, monospace;
  font-size: 40px;
  font-weight: 400;
  line-height: 53px;
  color: $color-rich-black;
}

@mixin text-subtitle {
  font-family: "Avenir Next Bold", monospace;
  font-size: 20px;
  line-height: 27px;
  color: $color-rich-black;

  @media (max-width: $breakpoint-mobile) {
    font-size: 16px;
    line-height: 21px;
  }
}

@mixin text-small-subtitle {
  font-family: "Avenir Next Bold", monospace;
  font-size: 16px;
  line-height: 22px;
  color: $color-rich-black;
}

@mixin text-body {
  font-family: Castoro, monospace;
  font-size: 18px;
  line-height: 24px;
  color: $color-rich-black;

  @media (max-width: $breakpoint-mobile) {
    font-size: 16px;
    line-height: 21px;
  }
}

@mixin text-body-italic {
  font-family: "Castoro Italic", monospace;
  font-size: 18px;
  line-height: 24px;
  font-style: italic;
  color: $color-rich-black;
}

@mixin text-small {
  font-family: "Avenir Next", monospace;
  font-size: 14px;
  line-height: 19px;
  color: $color-rich-black;
}

@mixin text-small-castoro {
  font-family: Castoro, monospace;
  font-size: 15px;
  line-height: 19px;
  color: $color-rich-black;
}

@mixin text-small-bold {
  font-family: "Avenir Next Demi", monospace;
  font-size: 14px;
  line-height: 19px;
  color: $color-rich-black;
}

@mixin text-small-bold-castoro {
  font-family: "Avenir Next Bold", monospace;
  font-size: 10px;
  line-height: 19px;
  color: $color-rich-black;
}

@mixin text-md {
  font-family: "Avenir Next", monospace;
  font-size: 17px;
  line-height: 19px;
  color: $color-rich-black;
}

@mixin text-link {
}

@mixin text-link-hover {
}