@import "src/styles/text";
@import "src/styles/colors";

.draft-quiz {


  &-title {
    @include text-page-title;
    margin-bottom: 40px;
  }

  .navigation-tabs {
    margin-bottom: 52px;
  }

  &-subtitle {
    @include text-small-subtitle;
    margin-bottom: 24px;
  }

  &-duedate {
    margin-bottom: 55px;

    .select-container {
      margin-right: 12px;
    }
  }

  &-mode {
    margin-bottom: 36px;

    &-option {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      min-width: 130px;
      margin-right: 12px;
      @include text-small-bold;
      padding: 6px 19px;
      border-radius: 30px;
      border: 1px solid $color-rich-black;

      &-active {
        background: $color-rich-black;
        color: $color-snow-white;
      }
    }
  }

  &-footer {
    text-align: right;

    .btn {
      margin-right: 24px;
    }
  }
}