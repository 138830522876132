@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

.faqs {
  padding: 120px 60px 90px;

  @media (max-width: $breakpoint-tablet) {
    padding: 90px 30px 70px;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 60px 20px 40px;
  }

  &-title {
    margin-bottom: 60px;
    color: $color-black;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 40px;
    }

    @media (max-width: $breakpoint-mobile) {
      text-align: center;
    }
  }

  &-questions {
    display: flex;

    @media (max-width: $breakpoint-tablet) {
      display: block;
    }

    &-col {
      flex: 1;

      &:first-child {
        margin-right: 90px;

        @media (max-width: $breakpoint-tablet) {
          margin-right: 0;
        }
      }

      .text-dropdown {
        &-main {
          color: $color-black;
        }

        &-body {
          color: $color-dark-grey;
        }
      }
    }
  }
}