@import "src/styles/colors";
@import "src/styles/text";

.payment-chiclet {
  display: inline-block;
  padding: 5px 11px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid $color-rich-black;
  margin-bottom: 16px;
  background: #B8DDDD;
  vertical-align: top;

  &-icon {
    margin-right: 1px;
    vertical-align: text-bottom;
  }

  &-text {
    display: inline-block;
    vertical-align: bottom;
    @include text-small;
  }
}