@import "src/styles/colors";
@import "src/styles/text";

.applicant-info {
  .section-header {
    margin-bottom: 16px;

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 12px;

      .header-line {
        flex: 1;
        height: 1px;
        background-color: black;
        margin: 0 20px;
        margin-top: 3px;
      }

      .header-left {
        display: flex;
        align-items: center;
        gap: 12px;

        h2 {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
          color: black;
        }

        &::after {
          content: '';
          display: block;
          height: 1px;
          background-color: #909090;
          margin-top: 5px;
        }

        &.work-history::after {
          width: 890px;
        }

        &.education-history::after {
          width: 850px;
        }
      }

      .collapse-icon {
        cursor: pointer;
        transition: transform 0.3s ease;

        &.expanded {
          transform: rotate(180deg);
        }
      }
    }
  }

  .applicant-profile-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .info-header {
      display: flex;
      align-items: center;
      gap: 12px;

      h2 {
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        color: black;
      }
    }

    .document-item-download {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      text-decoration: underline;
      font-family: "Avenir Next Bold", monospace;
      font-size: 17px;
      color: #212121;

      &-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .info-content {
    .experience-block {
      background-color: #F7F7F7;
      border: 1px solid #909090;
      border-radius: 30px;
      padding: 20px;

      .detail-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:first-child {
          margin-right: 200px;
        }

        .single-line-item {
          display: flex;
          flex-direction: column;
          gap: 8px;

          label {
            font-size: 14px;
            font-weight: bold;
            color: black;
          }

          span {
            font-size: 14px;
            color: black;
          }
        }
      }
    }
  }

  .info-header-notes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .notes-section {
      display: flex;
      align-items: center;
      gap: 12px;

      h2 {
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        color: black;
      }
    }

    .edit-button {
      background: none;
      border: none;
      padding: 8px;
      cursor: pointer;
      color: #666;

      &:hover {
        color: #333;
      }
    }
  }

  .notes-content {
    textarea {
      width: calc(100% - 32px) !important;
      margin-bottom: 24px;
      height: 100px !important;
      border-radius: 10px;
    }

    .button-group {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }

    &::after {
      content: '';
      display: block;
      height: 1px;
      background-color: #909090;
      margin-top: 5px;
    }
  }

  .no-certificates {
    @include text-body;
    color: #909090;
  }

  .history-content {
    background-color: white;
    border: 1px solid #909090;
    border-radius: 30px;

    .history-item {
      padding: 24px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #909090;
        width: 100%;
      }

      &:last-child {
        border-bottom: none;

        &::after {
          display: none;
        }
      }

      background: linear-gradient(to bottom,
        #F7F7F7 0%,
        #F7F7F7 calc(44px + 20px),
        #909090 calc(44px + 20px),
        #909090 calc(44px + 21px),
        transparent calc(44px + 21px),
        transparent 100%);

      &:first-child {
        background: linear-gradient(to bottom,
            #F7F7F7 0%,
            #F7F7F7 calc(44px + 20px),
            #909090 calc(44px + 20px),
            #909090 calc(44px + 21px),
            transparent calc(44px + 21px),
            transparent 100%);
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        overflow: hidden;
      }

      .item-header {
        margin-bottom: 20px;
        display: flex;
        gap: 10px;

        h3 {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
          color: black;
        }

        .currently-working {
          display: block;
          font-size: 12px;
          font-style: italic;
          color: #6B7280;
          margin-top: 4px;
        }
      }

      .info-line {
        display: flex;
        gap: 24px;
        margin-bottom: 16px;
        margin-top: 50px;

        .info-group {
          flex: 1;
          gap: 8px;
          display: flex;
          flex-direction: column;

          label {
            display: block;
            font-size: 14px;
            font-weight: bold;
            color: black;
            margin-bottom: 4px;
          }

          span {
            font-size: 14px;
            color: black;
          }
        }
      }

      .description-section {
        margin-top: 20px;

        label {
          display: block;
          font-size: 14px;
          font-weight: bold;
          color: black;
          margin-bottom: 8px;
        }

        p {
          font-size: 14px;
          color: black;
          line-height: 1.5;
          margin: 0;
        }
      }

      .documents-section {
        margin-top: 2px;
        padding-top: 24px;

        .documents-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;

          h4 {
            font-size: 14px;
            font-weight: bold;
            margin: 0;
            color: black;
          }

          .download-all {
            display: flex;
            align-items: center;
            gap: 8px;
            background: none;
            border: none;
            color: #212121;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
            padding: 0;
            text-decoration: underline;

            span {
              margin-top: 2px;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .documents-list {
          .no-documents {
            text-align: center;
            color: #6B7280 !important;
            padding: 12px 0;
            @include text-small-castoro;
          }
          .document-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px dashed #979797;
            padding-bottom: 12px;
            margin-bottom: 12px;

            &:last-child {
              border-bottom: none;
              padding-bottom: 0;
              margin-bottom: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }

            .document-info {
              display: flex;
              align-items: center;
              gap: 12px;

              .document-text {
                .document-title {
                  @include text-small-bold;
                  color: black;
                  display: block;
                }

                .document-description {
                  font-size: 12px;
                  color: #6B7280;
                  margin: 4px 0 0 0;
                }
              }
            }

            .document-actions {
              display: flex;
              align-items: center;
              gap: 12px;

              .toggle-description {
                cursor: pointer;
                color: #666;
                transition: transform 0.3s ease;

                &:hover {
                  color: #333;
                }

                &.expanded {
                  transform: rotate(180deg);
                }
              }
            }

            .download-button {
              background: #FFD500;
              padding: 8px 16px;
              border-radius: 20px;
              border: 1px solid black;
              color: #1a1a1a;
              cursor: pointer;
              font-weight: 500;
              text-decoration: none;
              font-size: 14px;
              text-align: center;

              &:hover {
                background-color: #FCD34D;
              }
            }
          }
        }
      }
    }
  }

  .info-section {
    margin-bottom: 32px;

    &-my-tools {
      background-color: #F7F7F7;
      border: 1px solid #909090;
      padding: 20px;
      border-radius: 30px;
      margin-top: 20px;
    }
  }
}