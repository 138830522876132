@import "src/styles/colors";
@import "src/styles/text";

.status-chiclet {
  display: inline-block;
  padding: 5px 11px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid $color-rich-black;
  @include text-small;
  background: rgba($color-gold, 0.2);

  &-container {
    display: inline-block;
    background: $color-snow-white;
    border-radius: 30px;
    margin-bottom: 16px;
    margin-left: 8px;
  }
}