@import 'src/styles/text';
@import "src/styles/resolution";

.forgot-password {
  padding: 24px;

  &-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 343px;
    @media (max-width: $breakpoint-mobile) {
      margin-top: 24px;
    }
  }

  &-title {
    @include text-page-title;
    margin-bottom: 24px;
  }

  &-text {
    @include text-body;
    margin-bottom: 24px;
  }

  &-email {
    margin-bottom: 24px;
  }

  &-button {
    .btn {
      width: 100%;
    }
  }
}