@import "src/styles/text";

.password-requirements-check {
  &-icon {
    display: inline-block;
    margin-right: 8px;
  }

  &-label {
    @include text-body;
    display: inline-block;
    vertical-align: super;
  }
}
