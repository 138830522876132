@import "src/styles/colors";
@import "src/styles/text";
@import "src/styles/cards";

.program-admin-card {
  @include regular-card;
  height: 166px;
  width: calc(100% - 50px);

  .status-chiclet-container {
    margin-left: 0;
    margin-right: 16px;
  }

  .skill-chiclet {
    margin-left: 16px;
  }

  .payment-chiclet {
    margin-right: 16px;
  }


  &-title {
    margin-bottom: 8px;
  }

  &-subtitle {
    @include text-small;
    margin-bottom: 8px;
  }

  &-favourite {
    position: relative;
    left: 0;
    top: 0;
    text-align: left;
    margin-bottom: 16px;

    &-icon {
      margin-right: 8px;
      cursor: initial;
      vertical-align: middle;
    }

    &-text {
      @include text-small;
      display: inline-block;
    }
  }

  &-footer {
    position: absolute;
    bottom: 24px;
    right: 24px;
    width: calc(100% - 48px);

    &-warning {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;

      img {
        margin-right: 6px;
        vertical-align: bottom;
      }

      &-text {
        @include text-small;
        color: $color-error-red;
      }
    }

    .btn {
      margin-left: 16px;
      float: right;
    }
  }

  &-green {
    background: rgba($color-green, 0.2);
  }

  &-yellow {
    background: rgba($color-dark-yellow, 0.2);
  }

  &-red {
    background: rgba($color-error-red, 0.1);
  }

  &-white {
    background: $color-snow-white;
  }
}