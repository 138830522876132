@import "src/styles/text";

.header-notifications {
  position: relative;
  
  .notification-dot {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    background-color: #4CAF50;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-weight: bold;
  }
}

.notifications-container {
  position: absolute;
  top: 100%;
  right: 0;
  width: 320px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  border: 1px solid rgb(155, 154, 154);

  &.mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    border-radius: 0;

    .notifications-list {
      max-height: calc(100vh - 120px);
      height: calc(100vh - 120px);
    }
  }

  .notifications-header {
    @include text-small-bold-castoro;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
    }

    .close-button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 4px;
      
      &:hover {
        background-color: #f5f5f5;
        border-radius: 50%;
      }
    }
  }

  .notifications-list {
    max-height: 60vh;
    overflow-y: auto;

    .no-notifications {
      padding: 32px;
      text-align: center;
      color: #807f7f !important;
      @include text-body;
      border-top: 1px solid #cbcbcb;
    }

    .notification-item {
      padding: 12px;
      cursor: pointer;
      border-bottom: 1px solid #b7b7b7;
      display: flex;
      align-items: flex-start;
      padding-left: 24px;
      position: relative;
  
      &:hover {
        background-color: #f5f5f5;
      }
  
      &.unread {
        &::before {
          content: '';
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #4CAF50;
        }
      }
  
      .notification-content {
        text-align: left;
        flex: 1;
  
        p {
          @include text-small-castoro;
          margin: 0 0px 4px 15px;
          text-align: left;
          color: black;
        }
  
        .timestamp {
          @include text-small;
          color: #666;
          margin: 0 0px 0px 15px;
        }
      }
    }
  }

  .notifications-footer {
    border-top: 1px solid #b7b7b7;
    text-align: center;

    .view-all {
      background: #eeeded;
      border: none;
      color: #ffc107 !important;
      cursor: pointer;
      width: 100%;
      padding: 8px 0;
      @include text-small-subtitle;
      
      &:hover {
        opacity: 0.8;
      }
    }
  }
}