@import "src/styles/text";
.close-delete-icon {
  @include text-small;
  width: 75px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;

  &-disabled {
    color: rgba($color-rich-black, 0.5);
    cursor: not-allowed;
  }
}