@import "src/styles/text";

.user-programs {
  min-height: 100vh;
  &-no-recommendations {

  }

  min-height: 100vh;

  &-title {
    @include text-page-title;
    margin-bottom: 8px;
  }

  &-text {
    @include text-body;
    margin-bottom: 24px;
  }

  &-report {
    margin-bottom: 24px;

    .btn {
      margin-right: 8px;
    }
  }

  &-empty {
    width: 100%;
    height: calc(100vh - 72px - 48px);
    position: relative;

    &-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-text {
      @include text-body;
      margin-bottom: 24px;
      text-align: center;
    }

    &-button {
      text-align: center;
    }
  }
}
