@import "src/styles/colors";
@import "src/styles/text";

.skill-chiclet {
  display: inline-block;
  padding: 5px 11px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid $color-rich-black;
  background: $color-snow-white;
  margin-bottom: 16px;
  margin-left: 8px;
  @include text-small;
  color: $color-rich-black;

  &-clickable {
    cursor: pointer;

    &:hover {
      background: rgba($color-rich-black, 0.1);
    }
  }

  &-active {
    background: $color-rich-black;
    color: $color-snow-white;

    &:hover {
      background: $color-rich-black;
    }
  }
}