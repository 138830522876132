.date-range-filter {
    --selected-color: #FFEA80;
    --border-color: hsl(220, 13%, 91%);
    --text-color: #111827;
    position: relative;
    display: inline-block;
  
    .filter-button {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
      background: white;
      border: none;
      border-radius: 6px;
      font-size: 16px;
      font-family: Castoro, monospace;
      cursor: pointer;
      transition: background-color 0.2s;
    }
  
    .dropdown-container {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 4px;
      z-index: 50;
    }
  
    .interaction-dropdown {
      background: white;
      border: 1px solid var(--border-color);
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
      min-width: 160px;
      padding: 4px;
    }
  
    .interaction-option {
      padding: 10px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.2s;
      border-radius: 6px;
  
      span {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--text-color);
        font-size: 14px;
      }
  
      &:hover {
        background-color: #F9FAFB;
      }
  
      &.active {
        background-color: var(--selected-color);
      }
    }
  
    .calendar-dropdown {
        position: absolute;
        left: calc(100% + 0px);
        top: 0;
        // transform: translateY(-15%);
        background: white;
        border: 1px solid var(--border-color);
        border-radius: 16px;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
        padding: 16px;
        margin-bottom: 24px; 
      }
  
    .calendars-container {
      display: flex;
      gap: 24px;
      margin-bottom: 16px;
    }
  
    .calendar {
      width: 240px;
  
      .calendar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        padding: 0 4px;
        position: relative;
  
        .calendar-month {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          font-weight: 500;
          color: var(--text-color);
          font-size: 13px;
        }
  
        .calendar-nav-btn {
          padding: 2px;
          border: none;
          background: none;
          cursor: pointer;
          border-radius: 4px;
          color: var(--text-color);
          z-index: 1;
  
          &:hover {
            background-color: #F9FAFB;
          }
        }
      }
  
      .weekdays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        margin-bottom: 4px;
  
        .weekday {
          text-align: center;
          color: #6B7280;
          font-size: 12px;
          font-size: 11px;
          padding: 2px;
        }
      }
  
      .days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 1px;
  
        .day {
          height: 28px;
          width: 28px;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 14px;
          color: var(--text-color);
          position: relative;
          z-index: 1;
  
        //   &:hover:not(.disabled) {
        //     background-color: #F9FAFB;
        //   }
  
          &.other-month {
            color: #9CA3AF;
          }
  
          &.today {
            font-weight: 600;
          }
  
          &.in-range {
            background-color: var(--selected-color);
          }
        
          &.selected-start {
            position: relative;
            overflow: visible;
        
            &::after {
              content: '';
              position: absolute;
              left: 50%;
              right: 0;
              top: 0;
              bottom: 0;
              background-color: var(--selected-color);
              z-index: -1;
            }
          }
        
          &.selected-end {
            position: relative;
            overflow: visible;
        
            &::after {
              content: '';
              position: absolute;
              left: 0;
              right: 50%;
              top: 0;
              bottom: 0;
              background-color: var(--selected-color);
              z-index: -1;
            }
          }
        
          &.selected-start,
          &.selected-end {
            background-color: var(--selected-color);
            border-radius: 50%;
            z-index: 2;
          }
  
          &.disabled {
            color: #D1D5DB;
            cursor: not-allowed;
            background-color: transparent;
          }
        }
      }
    }
  
    .calendar-actions {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      margin-top: 16px;
      padding-top: 16px;
      margin: 16px;
    }
  }