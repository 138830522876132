@import "src/styles/colors";
@import "src/styles/text";

.access-code-container {
  input {
    width: 18px;
    height: 22px;
    margin-right: 8px;
    border-radius: 16px;
    border: 1px solid rgba($color-rich-black, 0.5);
    box-shadow: none;
    outline: inherit;
    padding: 10px 12px;
    text-align: center;
    @include text-body;
  }

  &-error {
    input {
      border: 1px solid $color-error-red;
      background: rgba($color-error-red, 0.1);
    }
  }

  &-resent {
    margin-top: 16px;
    @include text-small;
    color: rgba($color-rich-black, 0.6);

    &-trigger {
      color: $color-rich-black;
      cursor: pointer;
    }
  }
}