@import "../../../../styles/v2/text";
@import "../../../../styles/v2/colors";

.btn-v2 {
  border-radius: 100px;
  cursor: pointer;
  gap: 8px;
  vertical-align: middle;

  svg {
    vertical-align: middle;
  }

  &-regular {
    padding: 15px 20px;
    border: 1px solid $color-blue;
    background: $color-white;
    height: 51px;
    color: $color-blue;

    svg {
      width: 20px;
      margin-right: 4px;
    }

    &:hover {
      background: $color-blue;
      color: $color-white;

      svg {
        path {
          stroke: $color-white;
        }
      }
    }
  }

  &-large {
    padding: 20px 30px;
    background: $color-blue;
    height: 66px;
    border: 0;
    color: $color-white;

    svg {
      width: 24px;
      margin-right: 8px;

      path {
        stroke: $color-white;
      }
    }

    &:hover {
      background: $color-black;
      color: $color-white;

      svg {
        path {
          stroke: $color-white;
        }
      }
    }
  }

  &-tool {
    padding: 30px 40px;
    background: $color-blue;
    height: 86px;
    border: 0;
    color: $color-white;

    svg {
      width: 24px;
      margin-right: 8px;

      path {
        stroke: $color-white;
      }
    }

    &:hover {
      background: $color-black;
      color: $color-white;

      svg {
        path {
          stroke: $color-white;
        }
      }
    }
  }

  &-pill {
    padding: 10px 12px;
    height: 47px;
    border: 1px solid $color-light-grey;
    background: $color-white;
    opacity: 0.5;

    h6 {
      vertical-align: middle;

      img {
        vertical-align: text-bottom;
        margin-right: 4px;
      }
    }

    &:hover {
      opacity: 1;
      border: 1px solid $color-black;
    }

  }

}