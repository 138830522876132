@import '../../../styles/colors';
@import '../../../styles/text';
@import '../../../styles/resolution';

.save-linkedin {
    max-width: 800px;
    margin: 100px auto;
    padding: 0 200px;

    &-title {
        @include text-subtitle;
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 24px;
        margin-left: 40px;
    }

    &-description {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 40px;
        margin-left: 40px;
    }

    &-link {
        color: #ffb700;
        text-decoration: underline;
    }

    &-steps {
        display: flex;
        flex-direction: column;
    }

    &-step {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 34px;
        border-bottom: 1px solid #e0e0e0;

        &:first-child {
            border-top: 1px solid #e0e0e0;
        }

        &-number {
            @include text-body;
            font-size: 46px;
            font-weight: 300;
            min-width: 32px;
            text-align: center;
        }

        &-text {
            font-size: 16px;
            line-height: 1.5;
            padding-top: 4px;
            max-width: 500px;
        }
    }

    @media (max-width: $breakpoint-mobile) {
        margin: 24px auto;
        padding: 0 20px;

        &-title {
            font-size: 24px;
            margin-bottom: 16px;
        }

        &-description {
            font-size: 14px;
            margin-bottom: 32px;
        }

        &-steps {
            gap: 24px;
        }

        &-step {
            gap: 16px;
            padding: 16px;

            &-number {
                font-size: 20px;
                min-width: 24px;
            }

            &-text {
                font-size: 14px;
            }
        }
    }
}