@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

.text-dropdown {
  overflow: hidden;

  &-main {
    margin-bottom: 20px;
    height: 50px;
    vertical-align: middle;
    border-bottom: 1px solid $color-light-grey;
    cursor: pointer;

    &-image {
      float: right;
      transition: transform 0.15s linear;

      &-close {
        transform: rotate(45deg);
      }
    }
  }

  &-body {
    max-height: 0;
    padding-bottom: 0;
    transition: max-height 300ms ease-out, padding-bottom 400ms ease-out;

    &-small {
      max-height: 200px;
      transition: max-height 200ms ease-out, padding-bottom 250ms ease-out;
    }

    &-opened {
      max-height: 410px;
      padding-bottom: 20px;

      @media (max-width: $breakpoint-mobile) {
        max-height: 210px;
      }
    }

    .text-dropdown-body-opened.text-dropdown-body-small {
      max-height: 200px;

      @media (max-width: $breakpoint-mobile) {
        max-height: 110px;
      }
    }
  }
}