@import "src/styles/v2/text";
@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

.linkedin-modal {
  @include text-v2;

  .ReactModal {
    &__Content {
      border-radius: 30px !important;
      width: calc(100vw - 120px - 180px);
      height: 80vh;
      padding-left: 90px !important;
      padding-right: 90px !important;


      @media (max-width: $breakpoint-tablet) {
        padding: 30px !important;
        width: calc(100vw - 120px);
      }

      @media (max-width: $breakpoint-mobile) {
        padding: 30px 20px !important;
        width: calc(100vw - 40px) !important;
        left: 0 !important;
      }
    }

    &__Overlay {
      background-color: rgba($color-black, 0.6) !important;
      z-index: 10000000;
    }
  }

  &-body {
    width: 70%;

    @media (max-width: $breakpoint-tablet) {
      width: auto;
      margin-bottom: 20px;
    }
  }

  &-close {
    color: $color-dark-grey;
    margin-bottom: 65px;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 30px;
    }

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 40px;
    }


    &-icon {
      vertical-align: sub;
      margin-right: 6px;
      cursor: pointer;
    }
  }

  &-title {
    width: 80vw;
    $color: $color-black;
    margin-bottom: 30px;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 20px;
      width: auto;
    }
  }

  &-subtitle {
    $color: $color-dark-grey;
    margin-bottom: 20px;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 0;
    }

    a {
      color: $color-blue;
      text-decoration: underline dotted;
      line-height: 1.3;
    }
  }

  &-instruction {
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-light-grey;

    @media (max-width: $breakpoint-tablet) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @media (max-width: $breakpoint-tablet) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &-index {
      display: inline-block;
      color: $color-white;
      padding-top: 1px;
      width: 30px;
      height: 29px;
      background: $color-black;
      text-align: center;
      border-radius: 50%;
      vertical-align: top;
      margin-right: 20px;

      @media (max-width: $breakpoint-mobile) {
        width: 24px;
        height: 23px;
        margin-right: 10px;
        vertical-align: bottom;
      }
    }

    &-text {
      display: contents;
      color: $color-black;

      a {
        color: $color-blue;
        text-decoration: underline dotted;
      }
    }

    &-image {
      margin-top: 40px;
      margin-left: 50px;

      @media (max-width: $breakpoint-tablet) {
        margin-top: 30px;

        img {
          width: 100%;
        }
      }

      @media (max-width: $breakpoint-mobile) {
        margin-top: 20px;
        margin-left: 0;

        img {
          width: 100%;
        }
      }
    }
  }

  &-help {
    margin-top: 60px;
    margin-bottom: 20px;
    color: $color-black;

    @media (max-width: $breakpoint-tablet) {
      margin-top: 30px;
      margin-bottom: 10px;
    }

    @media (max-width: $breakpoint-tablet) {
      margin-top: 40px;
    }
  }

  &-contact {
    color: $color-dark-grey;

    a {
      color: $color-dark-grey;
      text-decoration: none;
    }
  }
}