@import "src/styles/text";

.worker-dashboard {
  width: 100%;

  .worker-dashboard-title {
    @include text-page-title;
    margin-bottom: 24px;
  }

  .employee-profile {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    .employee-avatar {
      .avatar-circle {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: black;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        font-weight: bold;
        font-family: 'Castoro';
      }
    }

    .employee-info {
      margin-left: 10px;

      .employee-name {
        font-family: 'Castoro';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;
      }

      .employee-team {
        font-family: 'Avenir Next Bold';
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .performance-review {
    font-size: 20px;
    margin-bottom: 30px;
    font-family: 'Castoro';

    hr {
      border: none;
      border-top: 1px solid #FFD500;
      margin: 2px 0;
      height: 1px;
      background-color: #FFD500;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      max-width: 190px;
    }
  }

  &-results {

    .result-card {
      margin-right: 24px;
    }
  }
}
