@import "src/styles/resolution";
@import "src/styles/text";

.complex-search {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: $breakpoint-13inch) {
    flex-wrap: wrap;
  }

  &-search-container {
    display: inline-block;
    margin-right: 16px;

    .search {
      display: inline-block;
      width: 100%;

      input {
        width: calc(100% - 82px);
        margin-bottom: 0;
      }
    }
  }


  .filter {
    margin-left: 24px;
  }

  .sorting {
    margin-left: 24px;
    width: fit-content;
    vertical-align: middle;
  }

  .toggle {
    margin-left: 24px;
  }

  .export-button {
    padding: 8px 0 8px 16px;
    cursor: pointer;
    text-decoration: underline;
    @include text-small-subtitle;
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 107px;

    &-container {
      margin-left: auto;
      order: 2;

      @media (max-width: $breakpoint-13inch) {
        margin-top: 12px;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .sorting {
    margin-left: 0 !important;
  }
}