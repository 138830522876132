@import "src/styles/colors";

@mixin table-border-radius {
  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  table tr:first-child th,
  table tr:first-child td {
    border-top: 1px solid rgba($color-rich-black, 0.5);
  }

  table tr:last-child th,
  table tr:last-child td {
    border-bottom: 1px solid rgba($color-rich-black, 0.5);
  }

  table tr th:first-child,
  table tr td:first-child {
    border-left: 1px solid rgba($color-rich-black, 0.5);
  }

  table tr th:last-child,
  table tr td:last-child {
    border-right: 1px solid rgba($color-rich-black, 0.5);
  }

  /* top-left border-radius */
  table tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }

  /* top-right border-radius */
  table tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }

  /* bottom-left border-radius */
  table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }

  /* bottom-right border-radius */
  table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
}

@mixin table-horizontal-borders {
  table {
    border-spacing: 0;
  }

  table tr th,
  table tr td {
    border-bottom: 1px solid rgba($color-rich-black, 0.5);
  }

  table tr th {
    border-top: solid 1px rgba($color-rich-black, 0.5);
  }
}

@mixin table-vertical-borders {
  table {
    border-spacing: 0;
  }

  table tr th,
  table tr td {
    border-right: 1px solid rgba($color-rich-black, 0.5);
  }
}