@import "src/styles/colors";

.opacity-view {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  background: $color-snow-white;
  opacity: .5;
  z-index: 10;
}