@import "src/styles/text";
@import "src/styles/colors";
@import "src/styles/resolution";
.assessment-container-content {
  max-width: 650px;
  padding-left:56px;
  min-height: 100vh;
  &-image {
    text-align: left;
    margin-bottom: 24px;
  }

  &-text {
    @include text-subtitle;
    text-align: start;
    width: 650px;
    float: left;
    margin-bottom: 24px;

  }

  &-title {
    @include text-subtitle;
    width: 650px;
    float: left;
    margin-bottom: 24px;
  }

  &-list {
    li {
      @include text-body;
      margin-bottom: 16px;
      width: 633px;
      float: left;
    }

    li:last-child {
      margin-bottom: 24px;
    }
  }

  &-footer {
    @include text-small;
    width: 650px;
    float: left;
  }

  table {
    border-collapse: collapse;
  }

  th, td {
    border: 1px solid rgba($color-rich-black, 0.6);
    border-collapse: collapse;
  }

  &-table {
    margin-bottom: 24px;
    width: 650px;
    float: left;

    td {
      padding: 8px 24px;
      @include text-body;
    }

    td:first-child {
      @include text-small-subtitle;
    }

    tr:first-child {

      td:first-child {
        border-left: 0;
        border-top: 0;
      }

      td {
        @include text-small-subtitle;
      }
    }
  }
  &-right-table {
    border-collapse: collapse;
    margin-bottom: 24px;
    width: 650px;
    float: left;
    border: 1px solid $color-rich-black;

    td {
      padding: 4px 17px;
      @include text-body;
      border-bottom: 1px solid $color-rich-black;
      border-left: 1px solid $color-rich-black;

      .radio_button_div{
        display: flex;
        justify-content: center;
        align-items: center;

        .radioInput {
          position: relative;
          width: 20px;
          height: 20px;
          background-color: white;
          border-radius: 50%;
          border: 2px solid $color-rich-black;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          cursor: pointer;
        }
        
        .radioInput:checked {
          background-color: white;
        }
        
        .radioInput:checked::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 12px;
          height: 12px;
          background-color: $color-rich-black;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }
        
      }
    }

    td:first-child {
      @include text-small-subtitle;
      // border-left: 1px solid transparent;
    }

    tr:first-child {

      td:first-child {
        border-left: 0;
        border-top: 0;
      }

      td {
        @include text-small-subtitle;
      }
    }
  }

  @media (max-width: $breakpoint-13inch) {
    width: 50%;
    min-height: 100vh;
    &-text {
      @include text-body;
      position:relative;
      width: 600px;
      float: right;
      margin-bottom: 24px;
    }
  
    &-title {
      @include text-subtitle;
      width: 630px;
      float: right;
      margin-bottom: 24px;
    }

    &-list {
      li {
        @include text-body;
        position:relative;
        font-size: 15px;
        margin-bottom: 16px;
        width: 630px;
        float: right;
        left:10px;
      }
    }

    &-footer {
      @include text-small;
      width: 640px;
      float: right;
    }

    &-right-table {
      margin-top:10px;
      position: relative;
      width: 100%;
      

      tr{
        
        td{
          font-size: 14px !important;
          padding: 0px;
          padding-top:10px;
          padding-bottom: 10px;
        }
      }
    }
  }

}