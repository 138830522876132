@import "src/styles/colors";
@import "src/styles/text";
@import "src/styles/resolution";

.toast {
  &-close {
    margin-left: 16px;
    cursor: pointer;

    svg {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-container {
    .Toastify {
      &__toast {
        border-radius: 10px;
        border: 1px solid $color-rich-black;
        padding: 8px 16px;
        min-height: 48px;

        &-container {
          width: auto;
          @media (max-width: $breakpoint-mobile) {
            margin: 24px;
          }
        }

        &--success {
          background: $color-green !important;
        }

        &-body {
          @include text-body;
          color: $color-rich-black;
          padding: 0
        }
      }
    }
  }
}