@import "src/styles/colors";
@import "src/styles/text";
@import "src/styles/cards";

.assessment-card {
  height: 217px;
  @include regular-card;
  width: calc(100% - 48px);

  &-icons {
    margin-bottom: 16px;

    &-container {
      margin-right: 16px;
      display: inline-block;
      height: 28px;

      &-icon {
        height: 28px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
      }

      &-text {
        @include text-small;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  &-description {
    @include text-body;
    width: 640px;
  }

  &-Completed {
    background: rgba($color-green, 0.2);
  }

  &-In_progress {
    background: rgba($color-dark-yellow, 0.2);
  }

  &-Overdue {
    background: rgba($color-error-red, 0.1);
  }

}