@import "src/styles/colors";

.menu-element {
  padding: 11px 16px;
  cursor: pointer;
  margin-bottom: 10px;

  &-icon {
    display: inline-block;
    margin-right: 8px;
  }

  svg {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
  }

  &-text {
    display: inline-block;
    vertical-align: middle;
    padding-top: 3px;
  }

  &-active {
    border-radius: 30px;
    background: rgba($color-gold, 0.5);
  }
}