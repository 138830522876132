@import "src/styles/text";

.quiz-intro {
  position: relative;
  max-width: 600px;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  margin-bottom: 60px;

  &-container {
    position: relative;
  }

  &-title {
    @include text-page-title;
    margin-bottom: 12px;
  }

  &-subtitle {
    @include text-subtitle;
    margin-bottom: 8px;
  }

  &-body {
    @include text-body;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &-description {
    @include text-body;
    padding-left: 0
  }

  &-footer {
    text-align: center;
  }
}