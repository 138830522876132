@import "src/styles/colors";
@import "src/styles/resolution";

@mixin header {
  position: relative;
  height: 47px;
  padding: 12px 24px 12px 16px;
  border-bottom: 1px solid rgba($color-rich-black, 0.5);

  &-logo {
    width: 281px;
    cursor: pointer;
  }

  @media (max-width: $breakpoint-mobile) {
    border-bottom: 0;
    width: 100%;
  }
}