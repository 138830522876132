@import "src/styles/colors";
@import "src/styles/text";

.navigation-tabs {
  @include text-body;
  position: relative;

  &-bottom {
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: $color-rich-black;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}