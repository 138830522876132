.certificate-date {
    display: flex;
    width: 50%;
  
    .select-container {
      display: inline-block;
      width: 180px;
      margin-right: 16px;
      vertical-align: top;
  
      &:last-child {
        margin-right: 0;
        margin-top: 22px;
      }
  
      .selectkmq__control {
        width: 100% !important;
        min-height: 48px;
      }
    }
  
    &-error {
      position: absolute;
      bottom: -20px;
      left: 0;
    }
  
    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 16px;
  
      .select-container {
        width: calc(50% - 8px);
        margin-right: 0;
  
        &:last-child {
          margin-top: 0;
        }
  
        .selectkmq__control {
          width: 100% !important;
        }
      }
    }
  
    @media (max-width: 480px) {
      flex-direction: column;
  
      .select-container {
        width: 100%;
  
        &:last-child {
          margin-top: -20px;
        }
      }
    }
  }