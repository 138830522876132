@import "src/styles/colors";
@import "src/styles/text";
@import "src/styles/cards";
@import "src/styles/resolution";
.summary-card {
  @include regular-card;
  //width: calc(100% - 48px);

  &-title {
    width: unset;
    display: inline-block;
    position: relative;
    margin-bottom: 33px;

    &-bottom {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      border-radius: 1px;
      background: $color-rich-black;
    }
  }

  &-list {
    &-number {
      @include text-page-title;

      td {
        padding-left: 80px;
      }

      td:first-child {
        padding-left: 0;
      }
    }

    &-name {
      @include text-body;

      td {
        padding-left: 80px;
      }

      td:first-child {
        padding-left: 0;
      }
    }
  }
}

@media (max-width: $breakpoint-13inch) {
  .summary-card {
    width: 900px !important;
  }
}