@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

.footer-v2 {
  display: flex !important;
  padding: 30px 60px;
  color: $color-mid-grey;

  @media (max-width: $breakpoint-tablet) {
    background: $color-lightest-grey;
  }

  @media (max-width: $breakpoint-mobile) {
    display: block !important;
  }

  &-col {
    flex: 1;

    @media (max-width: $breakpoint-mobile) {
      display: block !important;
      text-align: center;
    }

    span {
      color: $color-mid-grey !important;
      margin-left: 18px;


      @media (max-width: $breakpoint-mobile) {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    &:last-child {
      text-align: right;

      @media (max-width: $breakpoint-mobile) {
        margin-top: 14px;
        text-align: center;
      }
    }
  }
}