@import "src/styles/text";
@import "src/styles/colors";

$left-width: 638px;
$min-height: 600px;

.login {
  height: 100vh;
  min-height: $min-height;
  overflow: hidden;

  &-left {
    display: inline-block;
    height: 100vh;
    min-height: $min-height;
    width: $left-width;
    background: $color-rich-black;
    position: relative;

    &-logo {
      position: absolute;
      left: 24px;
      top: 24px;
      width: 195px;
    }

    &-text {
      position: absolute;
      width: 469px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include text-page-title;
      color: $color-snow-white;
    }
  }

  &-right {
    position: relative;
    display: inline-block;
    height: 100vh;
    min-height: $min-height;
    width: calc(100% - $left-width);
    vertical-align: top;
  }
}