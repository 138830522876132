@import "src/styles/text";
@import "src/styles/colors";
@import "src/styles/resolution";

.applicant-program-details {
  a {
    color: inherit;
  }

  &-mobile-header {
    display: none;
    min-height: unset!important;
    border-bottom: 1px solid rgba($color-rich-black, 0.3);
    margin-bottom: 28px;
  }

  table {
    width: 100%;
    min-height: 300px;
  }

  &-left {
    position: relative;
    width: 60%;
    vertical-align: top;

    &-subtitle {
      @include text-subtitle;
      margin-bottom: 16px;
      max-width: 650px;
    }

    &-text {
      @include text-body;
      margin-bottom: 16px;
      max-width: 650px;

      ol {
        margin-top: 0;
      }
    }

    &-divider {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      border-radius: 1px;
      background: rgba($color-rich-black, 0.3);
    }
  }

  &-right {
    width: 40%;
    padding-left: 24px;
    vertical-align: top;

    &-subtitle {
      @include text-subtitle;
      margin-bottom: 16px;
      max-width: 650px;
    }

    &-text {
      @include text-body;
      margin-bottom: 56px;
      max-width: 650px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .applicant-program-details-mobile-header {
    display: table;
  }
  .applicant-program-details-right {
    display: none;
  }

  .applicant-program-details-left-divider {
    display: none;
  }

  .applicant-program-details-right-text {
    margin-bottom: 22px;
  }
}