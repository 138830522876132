@import 'src/styles/text';

.config {
  padding-left: 100px;
  padding-top: 24px;

  &-title {
    @include text-page-title;
    margin-bottom: 24px;
  }

  input {
    margin-bottom: 24px;
  }

  &-subtitle {
    @include text-subtitle;
    margin-bottom: 16px;
  }

  .select-container {
    margin-right: 24px;
    margin-bottom: 24px;
  }
}