@import "src/styles/text";

.self-assessment-submit {
  max-width: 650px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  &-text {
    @include text-body;
    margin-top: 16px;
  }
}