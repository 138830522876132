@import "src/styles/colors";
@import "src/styles/text";

.document-request {
  display: flex;
  align-items: center;
  border-radius: 4px;
  gap: 3px;
  text-decoration: underline;
  @include text-small-subtitle;
  
  &-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    flex-shrink: 0;
  }
  
  &-received {
    color: gray;
    cursor: default;
    
    .document-request-icon {
      color: gray;
    }
  }
  
  &-link {
    color: $color-rich-black;
    text-decoration: underline;
    cursor: pointer;

    &-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}