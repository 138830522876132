@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

.eligibility {
  margin-left: 60px;
  margin-right: 60px;
  padding: 90px;
  border-radius: 30px;
  background: $color-lightest-grey;

  @media (max-width: $breakpoint-tablet) {
    margin-left: 30px;
    margin-right: 30px;
    padding: 60px 30px;
  }

  @media (max-width: $breakpoint-mobile) {
    margin-left: 10px;
    margin-right: 10px;
    padding: 40px 20px;
  }

  &-title {
    color: $color-black;
    margin-bottom: 60px;

    @media (max-width: $breakpoint-mobile) {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  ul {
    list-style-type: square;
    margin: 0;
  }

  li {
    color: $color-dark-grey;
    margin-bottom: 36px;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 24px;
    }

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 16px;
    }
  }

  li:last-child {
    margin-bottom: 0;
  }
}