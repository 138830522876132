@import "src/styles/text";

.faq {
  &-title {
    @include text-page-title;
    margin-bottom: 24px;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 20px;
    }

    @media (max-width: 576px) {
      font-size: 1.25rem;
      margin-bottom: 16px;
      text-align: center;
    }
  }

  &-heading {
    margin-bottom: 56px;

    @media (max-width: 768px) {
      margin-bottom: 40px;
    }

    @media (max-width: 576px) {
      margin-bottom: 32px;
    }

    &-title {
      @include text-subtitle;
      margin-bottom: 24px;

      @media (max-width: 768px) {
        font-size: 1.2rem;
        margin-bottom: 20px;
      }

      @media (max-width: 576px) {
        font-size: 1rem;
        margin-bottom: 16px;
      }
    }
  }
}
