@import "src/styles/colors";
@import "src/styles/text";

.alert-modal {
  .ReactModal {
    &__Content {
      width: 595px;
      border-radius: 20px !important;
      border: 1px solid rgba($color-rich-black, 0.5) !important;
      background: $color-snow-white !important;
      box-shadow: 0 2px 2px 0 rgba(33, 33, 34, 0.20) !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
      padding: 0 !important;
      height: auto !important;
      max-height: fit-content !important;

      @media screen and (max-width: 768px) {
        inset: 50% auto auto 50% !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
        padding: 0 !important;
        border: none !important;
        border-radius: 20px !important;
        max-height: 90vh !important;
        overflow: visible !important;
        position: relative !important;
        background: transparent !important;
        width: auto !important;
        min-width: auto !important;
      }
    }

    &__Overlay {
      background-color: rgba($color-rich-black, 0.5) !important;
      z-index: 10000;

      @media screen and (max-width: 768px) {
        overflow: hidden;
      }
    }
  }

  &-body {
    position: relative;
    padding: 24px;
    
    @media screen and (max-width: 768px) {
      background: $color-snow-white;
      border-radius: 20px;
      padding: 24px;
      margin: 0px 15px;
      position: relative;
      width: 80vw;
      max-height: calc(90vh - 48px);
      overflow-y: auto;
    }

    &-close {
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;
    }

    &-icon {
      margin-bottom: 16px;
      height: 56px;
    }

    &-title {
      @include text-subtitle;
      margin-bottom: 16px;

      @media screen and (max-width: 768px) {
        width: 100%;
        padding-right: 40px; // Space for close button
      }
    }

    &-text {
      @include text-body;
      margin-bottom: 16px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    &-footer {
      margin-top: 56px;
      text-align: right;

      @media screen and (max-width: 768px) {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        
        .btn {
          margin-left: 0;
          width: 100%;
        }
      }

      .btn {
        margin-left: 16px;
        @media screen and (max-width: 768px) {
          margin-left: 0px;
        }
      }
    }
  }
}