@import "src/styles/colors";
@import "src/styles/text";
@import "src/styles/resolution";

.footer {
  background: $color-rich-black;
  padding: 28px 136px;
  z-index: 10;
  position: relative;

  &-logo {
    vertical-align: top;
    width: 171px;
    margin-right: 280px;
  }

  &-links {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    top: 28px;
    left: 50%;
    transform: translateX(-50%);

    &-title {
      @include text-small-subtitle;
      color: $color-snow-white;
      margin-bottom: 16px;
    }

    &-text {
      @include text-body;
      color: $color-snow-white;
      margin-bottom: 8px;
      cursor: pointer;
    }
  }

  &-contact {
    display: inline-block;
    vertical-align: top;
    width: 228px;
    float: right;

    &-title {
      @include text-small-subtitle;
      color: $color-snow-white;
      margin-bottom: 16px;
    }

    &-text {
      @include text-body;
      color: $color-snow-white;
      margin-bottom: 8px;
    }
  }

  &-copyright {
    @include text-small;
    color: $color-snow-white;
    text-align: center;
    margin-top: 115px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .footer {
    min-height: 280px;

    &-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      &-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
      }
    }

    &-logo {
      margin-right: 0;
    }

    &-links {
      margin-right: 0;
      margin-bottom: 16px;
      position: relative;
      align-self: flex-start;
    }

    &-contact {
      margin-right: 0;
      margin-left: 80px;
      margin-top: 15px;
    }

    &-copyright {
      margin-top: 24px;
    }
  }
}