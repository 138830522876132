@import "src/styles/colors";
@import "src/styles/text";
@import "src/styles/resolution";

@mixin regular-card {
  position: relative;
  width: 1173px;
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 10px;
  border: 1px solid rgba($color-rich-black, 0.5);

  &-title {
    @include text-subtitle;
    width: 640px;
    margin-bottom: 16px;
  }
}


