@import "src/styles/text";

.admin-courses {

  &-title {
    @include text-page-title;
    margin-bottom: 4px;
  }

  &-subtitle {
    @include text-small;
    margin-bottom: 24px;
  }
}