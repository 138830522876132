.identity-providers {
  padding-top: 20px;

  &-logo {
    padding-left: 10px;
  }

  &-text {
    padding-left: 10px;
  }
}