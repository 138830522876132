@import "src/styles/text";
@import "src/styles/colors";

.sorting {
  display: inline-block;
  position: relative;
  width: 551px;

  &-active {
    &-text {
      @include text-body;
      display: inline-block;
      margin-right: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 82px);
    }

    &-icon {
      margin-right: 8px;
      vertical-align: text-bottom;
    }

    &-open-btn {
      cursor: pointer;
      vertical-align: text-bottom;
      float: right;
      position: relative;
      top: 2px;
    }
  }

  &-menu {
    position: absolute;
    top: 40px;
    right: 22px;
    padding: 8px;
    border-radius: 20px;
    border: 1px solid rgba($color-rich-black, 0.5);
    background: $color-snow-white;
    z-index: 1;

    &-option {
      &-text {
        @include text-body;
        width: max-content;
      }

      padding: 12px 16px;
      border-radius: 30px;
      cursor: pointer;
      width: calc(100% - 32px);

      &:hover {
        background: rgba($color-gold, 0.5);
      }
    }
  }
}

.dropdown {
  .sorting-menu {
    max-height: 280px;
    overflow-y: scroll;
  }
}