@font-face {
  font-family: 'Montreal Semi-bold';
  src: url("PPNeueMontreal-SemiBold.ttf");
}

@font-face {
  font-family: 'Montreal Regular';
  src: url("PPNeueMontreal-Regular.ttf");
}

@font-face {
  font-family: 'Montreal Medium';
  src: url("PPNeueMontreal-Medium.ttf");
}