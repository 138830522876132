@import "src/styles/colors";
@import "src/styles/text";

.document-request-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;

  &-title {
    @include text-page-title;
    margin-bottom: 24px;
  }

  .textarea {
    textarea {
      width: calc(100% - 32px) !important;
      margin-bottom: 24px;
      height: 200px !important;
    }
  }

  .select-container {
    width: 100%;
    margin-bottom: 24px;

    .selectkmq__control {
      width: 100%;
    }
  }
  .input-container {
    input, textarea {
      width: calc(100% - 32px) !important;
      margin-bottom: 24px;
    }
  }
}

.document-request-tabs {
  display: flex;
  border-bottom: 1px solid $color-rich-black;
  margin-bottom: 54px;

  .tab {
    padding: 12px 0px;
    cursor: pointer;

    &.active {
      border-bottom: 5px solid rgba(255, 217, 0, 0.761);
    }
  }
}

.request-form {
   &-title {
    @include text-small-subtitle;
    margin-bottom: 24px;
   }

  .custom-input-textarea {
    textarea {
      width: calc(100% - 32px) !important;
      margin-bottom: 8px;
      height: 25px !important;
    }
  }

  .form-field {
    margin-bottom: 16px;

    label {
      display: block;
      margin-bottom: 8px;
    }
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }
}