@import "../../../../styles/text";
@import "../../../../styles/header";

.assessment-header {
  @include header;
  height: 54px;

  &-logo {
    padding-top: 13px !important;
  }

  &-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    &-title {
      @include text-subtitle;
      display: inline-block;
      text-align: center;
    }

    &-instructions {
      @include text-body;
      color: rgba($color-rich-black, 0.8);
      text-align: center;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}