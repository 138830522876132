@import "src/styles/colors";
@import "src/styles/text";
.progress-bar-container {
  background-color: $color-snow-white;
  //display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-content {
    margin-top:10px;
    margin-bottom: 10px;
    position: relative;
    width: 514px;
    background-color: $color-snow-white;
    border: 1px solid #ddd;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow: hidden;
    @media (max-width: 768px) {
      width: 350px;
    }

    .progress {
        width: 0;
        height: 10px;
        background-color: $color-rich-black;
        border-radius: 30px;
        transition: width 0.6s ease-in-out;
    }
  }
  &-precent {
    @include text-subtitle;
  }

}
