@import "../../../styles/colors";
@import "../../../styles/text";


.no-certificates {
    @include text-body;
}

.submitted-certificate-resume-date {
    margin-bottom: 56px;
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    @media (max-width: 576px) {
        flex-direction: column;
    }

    .select-container {
        width: calc(25% - 14px) !important;
        margin-right: 14px !important;
        margin-bottom: 10px !important;

        @media (max-width: 768px) {
            width: 100% !important;
        }

        @media (max-width: 576px) {
            width: 100% !important;
        }

        &:nth-child(2) {
            margin-top: 24px;
        }

    }
}