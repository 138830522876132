@import "src/styles/resolution";

.applicant-courses {
  position: relative;

  &-hide-paid {
    position: absolute;
    right: 0;
    top: -112px;

    @media (max-width: $breakpoint-mobile) {
      text-align: right;
      margin: 12px 0;
      top: 0;
      position: relative;
    }
  }
}