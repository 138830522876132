@import "src/styles/text";

.quiz-outcomes {
  &-title {
    @include text-subtitle;
    width: calc(100% - 210px);
    display: inline-block;
    margin-right: 24px;
    margin-bottom: 48px;
  }

  .checkbox {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;

    &-text {
      @include text-small-subtitle;
    }
  }

  &-option {
    margin-bottom: 34px;

    &-text {
      @include text-small-subtitle;
      width: calc(100% - 210px);
      display: inline-block;
      margin-right: 24px;
    }
  }

  &-footer {
    margin-top: 50px;
    text-align: right;

    .btn {
      margin-right: 24px;
    }
  }
}