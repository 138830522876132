@import "src/styles/text";
@import "src/styles/colors";

.evidence-component-table {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    th {
      @include text-small;
      font-weight: 400;
      white-space: nowrap;
      padding: 8px 16px;

      position: sticky;
      z-index: 1;
      top: 76px;
      background: white;
    }

    td {
      @include text-body;
      padding: 8px 16px;
      vertical-align: top;
      text-align: left;
    }

    td:nth-child(2) {
      div {
        margin-bottom: 10px;
        cursor: pointer;
      }
    }

    th:nth-child(2) {
      overflow: unset!important;
      white-space: unset!important;
    }

    td:nth-child(1) {
      text-align: center;
    }

    th:last-child {
      border-right: 1px solid rgba($color-rich-black, 0.5);
    }

    td:last-child {
      position: relative;
      border-right: 1px solid rgba($color-rich-black, 0.5);
      min-width: 80px;
      min-height: 50px;

      .evidence-component-table-actions {
        cursor: pointer;

        &-approve {
          margin-right: 12px;
        }

        &-disabled {
          img {
            cursor: initial;
          }
        }
      }
    }
  }

  tr {
    border-spacing: 0 30px;

    td {
      border-bottom: 1px dashed #979797;
    }
  }

  tr:last-child {
    height: 60px;

    td {
      border-bottom: 1px solid rgba($color-rich-black, 0.5);
    }

    td:first-child {
      border-radius: 0 0 0 30px;
    }

    td:last-child {
      border-radius: 0 0 30px 0;
    }
  }

  table th:first-child,
  table td:first-child {
    border-left: 1px solid rgba($color-rich-black, 0.5);
  }

  &-more {
    font-family: "Avenir Next", monospace;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    cursor: pointer;
    min-width: 110px;
    right: 10px;
    top: 16px;

    img {
      vertical-align: middle;
      margin-right: 8px;
    }
  }

  &-title {
    border: 0 !important;
    padding: 0 !important;
    text-align: left;
    top: 0 !important;
    white-space: unset !important;
  }

  &-score {
    @include text-subtitle;
    margin-bottom: 8px;
  }

  &-chicklet {
    border-radius: 10px;
    font-family: "Avenir Next", monospace;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 16px;
  }

  .job-chicklet {
    background: #E1CBEE;
    color: #621C8C;
  }

  .education-chicklet {
    background: #DBEECB;
    color: #46791B;
  }

  .certification-chicklet {
    background: #CCE6FF;
    color: #1B558B;
  }

  &-sentence:hover {
    background: #FFEA80;
  }
}