@import "src/styles/text";
@import "src/styles/colors";

.assessment-details {
  &-back {
    position:relative;
    left:-52px;
    top: -100px;
  }

  &-title {
    @include text-page-title;
    margin-bottom: 24px;
    margin-top: 50px;
  }


  &-h2 {
    @include text-subtitle;
    margin-top: 21px;
    margin-bottom: 24px;
  }

  &-h3 {
    @include text-small-subtitle;
    margin-top: 21px;
    margin-bottom: 24px;
  }

  &-text {
    @include text-body;
  }

  &-left {
    position: relative;
    width: 750px;
    padding-right: 50px;

    &-divider {
      position: absolute;
      top: 100px;
      right: 0;
      height: 100%;
      width: 1px;
      border-radius: 1px;
      background: rgba($color-rich-black, 0.3);
    }
  }

  &-about {
    @include text-subtitle;
    margin-bottom: 16px;

    &-text {
      @include text-body;
      margin-bottom: 24px;
    }
  }

  &-list {
    &-title {
      @include text-subtitle;
      margin-bottom: 16px;
    }

    &-level {
      @include text-subtitle;
      margin-bottom: 8px;
    }

    &-text {
      @include text-body;
      margin-bottom: 16px;
    }
  }

  &-right {
    vertical-align: top;
    padding-left: 24px;
    
    .assessment-card-icons-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      margin-top: 130px;
      
      &-small {
        display: flex;
        justify-content: center;
        align-items: center;

        span{
          margin-bottom: 5px;
        }
      }
    }
  }
}