@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

.benefits {
  margin-left: 60px;
  margin-right: 60px;
  padding: 90px;
  border-radius: 30px;
  background: $color-lightest-grey;

  @media (max-width: $breakpoint-tablet) {
    margin-left: 30px;
    margin-right: 30px;
    padding: 60px 30px;
  }

  @media (max-width: $breakpoint-mobile) {
    margin-left: 10px;
    margin-right: 10px;
    padding: 40px 20px 30px;
  }

  &-title {
    margin-bottom: 80px;
    color: $color-black;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 60px;
    }

    @media (max-width: $breakpoint-mobile) {
      text-align: center;
      margin-bottom: 40px;
    }
  }

  &-list {
    list-style: decimal inside;
    padding: 0;

    li {
      margin-bottom: 80px;

      @media (max-width: $breakpoint-tablet) {
        margin-bottom: 40px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-title {
      display: inline-block;
      margin-bottom: 30px;
      color: $color-black;

      @media (max-width: $breakpoint-tablet) {
        margin-bottom: 20px;
      }
    }

    &-desc {
      margin-bottom: 40px;
      color: $color-dark-grey;

      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 30px;
      }
    }

    &-body {
      background: $color-white;
      border-radius: 20px;
      padding: 40px;
      width: 50%;
      color: $color-black;

      @media (max-width: $breakpoint-tablet) {
        width: auto;
      }

      @media (max-width: $breakpoint-mobile) {
        padding: 20px;
      }

      &-subtitle {
        margin-bottom: 20px;
        color: $color-black;

        @media (max-width: $breakpoint-mobile) {
          margin-bottom: 10px;
        }
      }

      &-text {
        color: $color-dark-grey;
      }
    }
  }

  li::marker {
    font-family: 'Montreal Semi-bold', serif;
    font-size: 2.25rem;
    line-height: 1.3;
    letter-spacing: -0.015em;

    @media (max-width: $breakpoint-tablet) {
      font-size: 2rem;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 1.5rem;
    }
  }
}