@import "src/styles/text";

.draft-quiz-question {
  margin-bottom: 54px;

  &-title {
    @include text-small-subtitle;
    margin-bottom: 24px;
  }

  &-body {
    &-text {
      display: inline-block;
      @include text-body;
      font-size: 16px;
      margin-bottom: 16px;
      width: calc(100% - 100px);
      margin-right: 22px;

      &-edit {
        text-align: right;

        .btn {
          margin-top: 12px;
        }
      }

      textarea {
        @include text-body;
        font-size: 16px;
        display: inline-block;
        width: calc(100% - 24px);
        border-radius: 20px;
        resize: none;
        padding: 12px;
      }
    }

    &-icons {
      vertical-align: top;
      display: inline-block;

      svg {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}