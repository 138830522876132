@import "../../../../../styles/colors";
@import "../../../../../styles/text";

.resume-applicant {
  position: relative;
  @include text-body;

  .textarea {
    textarea {
      width: calc(100% - 32px) !important;
      margin-bottom: 24px;
    }
  }

  .input-container {
    input, textarea {
      width: calc(100% - 32px) !important;
      margin-bottom: 24px;
    }
  }

  .input-container-error {
    input {
      margin-bottom: 4px;
    }

    .error-container {
      margin-bottom: 24px;
    }
  }
}