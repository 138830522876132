@import "src/styles/colors";
@import "src/styles/text";

.checkbox {
  display: flex;
  margin-bottom: 24px;
  cursor: pointer;

  &-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    margin-right: 8px;
  }

  &-text {
    @include text-body;
    color: $color-rich-black;
    display: inline-block;
    vertical-align: top;
    padding-top: 4px;

    &-bolder {
      color: $color-rich-black;
    }
  }
}