@import "src/styles/header";

.login-header {
  @include header;

  &-logo-fleming {
    cursor: pointer;
    width: 171px;
    position: absolute;
    left: 5px;
    top: 5px;
  }

  position: relative;
  border-bottom: 0;

  &-buttons {
    display: inline-block;
    position: absolute;
    right: 24px;
    top: 12px;

    .btn {
      margin-left: 16px;
    }
  }
}