@import "src/styles/colors";
@import "src/styles/text";

.navigation-tabs-element {
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  @include text-body;

  &-active {
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background: $color-gold;
    display: inline-block;
  }

  &-divider {
    width: 1px;
    height: 20px;
    position: absolute;
    bottom: 6px;
    right: 0;
    border-radius: 1px;
    background: $color-rich-black;
  }
}

.navigation-tabs-element:first-child {
  padding-left: 0;
}