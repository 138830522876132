@import "src/styles/colors";

.terms-and-policy {
  &-footer {
    text-align: center;
    padding-top: 24px;
  }
}

.ReactModal {
  &__Body--open {
    overflow: hidden;
  }

  &__Content {
    font-family: Castoro, monospace;
    width: 60vw;
    height: 80vh;
    min-width: 764px;
    border: 1px solid rgba($color-rich-black, 0.5) !important;
    background: $color-snow-white !important;
    box-shadow: 0 2px 2px 0 rgba(33, 33, 34, 0.20) !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    padding: 32px !important;

    @media (max-width: 768px) {
      top: 24px !important;
      width: 75vw !important;
      height: 90vh !important;
      min-width: unset;
      border: none !important;
      left: 35px !important;
      transform: none;
      padding: 16px !important;
    }
  }

  &__Overlay {
    background-color: rgba($color-rich-black, 0.5) !important;
    z-index: 10000;
  }
}