@import "src/styles/colors";
@import "src/styles/text";

.progress-chiclet {
  display: inline-block;
  padding: 5px 11px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid $color-rich-black;
  @include text-small;

  &-container {
    display: inline-block;
    background: $color-snow-white;
    border-radius: 30px;
    margin-bottom: 16px;
  }

  &-Completed {
    background: $color-green;
  }

  &-In_progress {
    background: $color-dark-yellow;
  }

  &-Overdue {
    background: rgba($color-error-red, 0.5);
  }

  &-Not_started {
    background: rgba($color-rich-black, 0.2);
  }

  &-Recommended {
    background: $color-rich-black;
    color: $color-snow-white;
  }
}