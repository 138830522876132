@import "src/styles/colors";
@import "src/styles/text";

.select-container {
  display: inline-block;


  .selectkmq {
    &__control {
      width: 343px;
      min-height: 32px;
      padding: 8px 16px;
      border-radius: 30px;
      border: 1px solid rgba($color-rich-black, 0.5);
      box-shadow: none;

      &:hover {
        border-color: rgba($color-rich-black, 0.5);
        cursor: pointer;
      }

      &--menu-is-open {
        border-radius: 20px 20px 0 0;
      }
    }

    &__single-value {
      @include text-body;
    }

    &__placeholder {
      margin-left: 0;
      @include text-body;
      color: rgba($color-rich-black, 0.6)
    }

    &__menu {
      margin-top: 0;
      border-radius: 0 0 20px 20px;
      border-right: 1px solid rgba($color-rich-black, 0.5);
      border-bottom: 1px solid rgba($color-rich-black, 0.5);
      border-left: 1px solid rgba($color-rich-black, 0.5);
      padding: 8px;

      ::-webkit-scrollbar {
        width: 10px;
      }

      ::-webkit-scrollbar-track {
        display: none;
      }

      ::-webkit-scrollbar-thumb {
        background: $color-rich-black;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 20px;
      }

      ::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }

      &-list {
        padding: 0;
        max-height: 192px;
        overflow-y: auto;
        position: relative;
      }
    }

    &__option {
      padding: 10px 0 10px 16px;
      margin-bottom: 8px;
      min-height: 44px;
      border-radius: 30px;
      cursor: pointer;
      @include text-body;

      &--is-focused {
        background: inherit;
      }

      &:hover {
        background: rgba($color-gold, 0.5);
      }

      &--is-selected {
        background-color: $color-gold;

        &:hover {
          background-color: $color-gold;
        }
      }
    }

    &__value-container {
      padding-left: 0;
    }

    &__input-container {
      margin-left: 0;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      svg {
        width: 30px;
      }
    }

    &__multi-value {
      border-radius: 7px;
      background: rgba($color-rich-black, 0.2);
    }
  }

  &-disabled {
    .selectkmq__control {
      background: rgba($color-rich-black, 0.6);
      cursor: default !important;
    }

    .selectkmq__placeholder {
      color: $color-snow-white;
    }

    .selectkmq__input {
      display: none;
    }
  }

  &-error {
    margin-bottom: 4px !important;

    .selectkmq__control {
      background: rgba($color-error-red, 0.1);
      border: 1px solid $color-error-red;
    }
  }
}