@import "src/styles/text";
@import "src/styles/colors";

.upload-employee-table {
  width: 100%;
  height: calc(100vh - 160px);
  overflow-y: auto;
  margin: 20px 0;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
    border: 0;
    border-radius: 0;

    th, td {
      border-bottom: 1px solid #212121;
      padding: 8px;
    }

    th {
      background-color: $color-snow-white;
      @include text-subtitle;
      color: #909090;
      text-align: left;
      border-bottom: 2px solid $color-rich-black;
      padding: 10px;
      font-weight: bold;
    }

    .error-row {
      td {
        background-color: $color-error-light-red;
      }
    }

    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background-color: $color-snow-white;
        }

        td {
          border-bottom: 1px solid $color-rich-black;
          padding: 10px;

          .table-name-cell {
            display: flex;
            align-items: center;

            .user-profile-avatar {
              width: 55px;
              height: 25px;
              border-radius: 50%;
              background-color: $color-rich-black;
              color: $color-snow-white;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
            }

            .table-name-cell-job {
              font-size: 12px;
              color: $color-rich-black;
            }
          }
        }
      }
    }
  }
}
