@import "src/styles/colors";
@import "src/styles/text";

.filter {
  display: inline-block;
  width: 237px;
  vertical-align: middle;
  position: relative;

  &-value {
    &-icon {
      vertical-align: text-bottom;
      margin-right: 8px;
    }

    &-text {
      display: inline-block;
      @include text-body;
      vertical-align: super;
      margin-right: 16px;
    }

    &-open-btn {
      vertical-align: text-bottom;
      position: relative;
      top: 2px;
      float: right;
      cursor: pointer;
    }
  }

  &-menu {
    width: 706px;
    position: absolute;
    top: 40px;
    left: 0;
    padding: 24px;
    border-radius: 30px;
    border: 1px solid rgba($color-rich-black, 0.5);
    background: $color-snow-white;
    z-index: 1;

    &-content {
      margin-bottom: 54px;

      &-left {
        display: inline-block;
        width: calc(50% - 12px);
        vertical-align: top;
        margin-right: 24px;
      }

      &-right {
        display: inline-block;
        width: calc(50% - 12px);
        vertical-align: top;
      }
    }

    &-option {
      .checkbox {
        margin-bottom: 0;
      }

      margin-bottom: 16px;
    }

    &-footer {
      position: relative;
      text-align: right;
      &-reset {
        position: absolute;
        left: 0;
        bottom: 0;
        @include text-body;
        color: rgba($color-rich-black, 0.6);
        cursor: pointer;
        display: inline-block;
        text-decoration: underline;
      }

      &-buttons {
        .btn {
          margin-left: 16px;
        }
      }
    }
  }
}