@import "src/styles/text";
@import "src/styles/colors";

.csv-uploader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;

  .file-upload-section {
    text-align: center;

    h2 {
      @include text-page-title;
    }

    p {
      @include text-body;
      margin: 10px 0;
    }
    .csv-label {
      cursor: pointer;
    }
    .csv-reader-input {
      @include text-body;
      min-width: 30px;
      width: 100px;
      align-content: center;
      padding: 0;
      margin: 0 auto;
      border-radius: 30px;
      background: $color-rich-black;
      color: $color-snow-white;
      height: 48px;
      cursor: pointer;
    }
    .csv-input {
      width: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      padding: 0;
      border: none;
    }
  }

  .error-message {
    color: $color-rich-black;
    text-align: left;
    margin: 0;
    padding: 7px 3px 7px 10px;
    width: 100%;
    background: #FDE8E6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 30px;
    gap: 8px;
  }

  .button-group {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;

    .btn {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

