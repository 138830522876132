@import "src/styles/text";
@import "src/styles/colors";
@import "src/styles/resolution";

.loading {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  @media (max-width: $breakpoint-mobile) {
    height: 110vh;
  }
  background: $color-snow-white;
  z-index: 1000;

  &-container {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
      width: 90%;
      padding: 0 16px;
    }
  }

  &-text {
    @include text-body;
    margin-bottom: 25px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 1.4;
    }
  }

  &-bar {
    margin-top: 25px;
    position: relative;
    height: 10px;
    width: 100px;
    background: $color-snow-white;

    @media (max-width: 768px) {
      margin-top: 20px;
      width: 80px;
      margin-left: auto;
      margin-right: auto;
    }

    &-progress {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      background: $color-error-red;
      height: 10px;
      width: 10px;

      @media (max-width: 768px) {
        height: 8px;
        width: 8px;
      }
    }
  }
}