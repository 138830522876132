@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

.how-it-works {
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 120px;
  color: $color-black;

  @media (max-width: $breakpoint-tablet) {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 90px;
  }

  @media (max-width: $breakpoint-mobile) {
    margin: 60px 10px;
    padding: 0;
  }

  &-title {
    margin-bottom: 60px;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 40px;
    }

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 30px;
    }
  }

  &-list {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
    display: flex;
    list-style: decimal outside;
    padding: 0;

    @media (max-width: $breakpoint-tablet) {
      margin-left: 2rem;
      display: block;
      margin-right: 0;
    }

    @media (max-width: $breakpoint-mobile) {
      margin-left: 1.5rem;
      margin-right: 0;
    }

    &-title {
      color: $color-black;
      margin-bottom: 20px;

      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 15px;
      }
    }

    &-text {
      color: $color-dark-grey;
    }

    li {
      flex: 1;
      text-align: left;
      position: relative;
      margin-right: calc(30px + 2.25rem);
      padding-right: 40px;

      @media (max-width: $breakpoint-tablet) {
        margin-right: 0;
        margin-bottom: 30px;
        padding-right: 0;
      }
    }

    li:last-child {
      margin-right: 0;
    }

    li::marker {
      font-family: 'Montreal Semi-bold', serif;
      font-size: 2.25rem;
      line-height: 1.3;
      letter-spacing: -0.015em;

      @media (max-width: $breakpoint-tablet) {
        font-size: 2rem;
      }

      @media (max-width: $breakpoint-mobile) {
        font-size: 1.5rem;
      }
    }
  }
}