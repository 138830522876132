@import 'src/styles/text';

.reset-password {
  margin-top: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 343px;

  .input-container {
    margin-bottom: 24px;
  }

  &-title {
    @include text-page-title;
    margin-bottom: 24px;
  }

  &-button {
    margin-bottom: 24px;

    .btn {
      width: 100%;
    }
  }

  .checkbox-text {
    @include text-small;
  }
}