@import "src/styles/colors";
@import "src/styles/text";
@import "src/styles/cards";
@import "src/styles/resolution";

.plar-card {
  @include regular-card;
  height: 166px;
  width: calc(100% - 50px);

  .status-chiclet-container {
    margin-left: 0;
  }

  .skill-chiclet {
    margin-left: 16px;
  }

  .payment-chiclet {
    margin-right: 16px;

    @media (max-width: $breakpoint-mobile) {
      display: block;
      margin-right: -16px;
    }
  }

  &-header {
    @include text-small;
    margin-bottom: 8px;
  }

  &-title {
    margin-bottom: 8px;
    max-width: calc(100% - 110px);
  }

  &-subtitle {
    @include text-small;
    margin-bottom: 8px;
    max-width: calc(100% - 110px);
  }

  &-favourite {
    position: absolute;
    right: 24px;
    top: 24px;
    text-align: right;

    &-icon {
      margin-right: 8px;
      cursor: pointer;
      vertical-align: middle;
    }

    &-text {
      @include text-small;
      display: inline-block;
    }

    &-disabled {
      position: relative;
      left: 0;
      top: 0;
      text-align: left;
      margin-bottom: 16px;

      img {
        cursor: initial;
      }
    }
  }

  &-footer {
    position: absolute;
    bottom: 24px;
    left: 24px;
    right: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 48px);

    &-labels {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
    }


    &-warning {
      display: inline-block;

      img {
        margin-right: 6px;
        vertical-align: bottom;
      }

      &-text {
        @include text-small;
        color: $color-error-red;
      }
    }

    .btn {
      margin-left: 16px;
    }

    &-due {
      @include text-small;
      font-size: 16px;
      margin-right: 20px;

      &-dot {
        border-radius: 50%;
        width: 9px;
        height: 9px;
        background: $color-green;
        display: inline-block;
        margin-right: 6px;
      }
    }

    .button-wrapper {
      display: flex;
    }
  }

  &-green {
    background: rgba($color-green, 0.2);
  }

  &-yellow {
    background: rgba($color-dark-yellow, 0.2);
  }

  &-red {
    background: rgba($color-error-red, 0.1);
  }

  &-white {
    background: $color-snow-white;
  }
}

@media (max-width: $breakpoint-mobile) {
  .plar-card {
    height: unset;
    padding-bottom: 163px;

    &-footer {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      .btn-primary {
        margin-bottom: 8px;
      }

      .button-wrapper {
        width: 100%;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}