@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

.navbar {
  border-bottom: 1px solid $color-lightest-grey;
  padding: 20px 60px;
  height: 50px;
  width: calc(100% - 120px);
  vertical-align: middle;
  position: fixed;
  background: $color-white;
  z-index: 100;

  @media (max-width: $breakpoint-tablet) {
    padding: 20px 20px;
    width: calc(100% - 40px);
    max-width: calc(100vw - 40px);
    height: 30px;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 20px 10px;
    width: calc(100% - 20px);
    max-width: calc(100vw - 20px);;
  }

  &-desktop {
    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }

  &-item {
    display: inline-block;
    border-radius: 8px;
    padding: 4px 8px;
    margin-right: 20px;
    vertical-align: middle;
    cursor: pointer;

    &-active {
      background: $color-lightest-grey;
    }

    &-logo {
      vertical-align: center;
      padding: 0;

      img {
        height: 40px;

        @media (max-width: $breakpoint-tablet) {
          height: auto;
          width: 175px;
        }
      }
    }

    font-family: 'Montreal Medium', serif;
    font-size: 1rem;
    line-height: 1.3;
    letter-spacing: 0;
    font-weight: 500;

    @media (max-width: $breakpoint-tablet) {
      font-size: 0.9375rem;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 1.5rem;
      font-weight: 800;
    }
  }

  &-right {
    float: right;

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }

    &-mobile {
      @media (max-width: $breakpoint-mobile) {
        display: inline-block;
        cursor: pointer;

        img {
          margin-top: 3px;
        }
      }

      @media (min-width: $breakpoint-tablet) {
        display: none;
      }
    }
  }

  &-menu {
    height: calc(100vh - 71px);
    width: 100%;
    position: fixed;
    left: 0;
    top: 71px;
    background: $color-white;
    padding-top: 44px;

    @media (min-width: $breakpoint-tablet) {
      display: none;
    }

    .navbar-item {
      display: block;
      text-align: center;
      margin-bottom: 20px;

      &-active {
        background: $color-white;
      }
    }

    &-button {
      text-align: center;
      margin-top: 30px;

      .btn-v2 {
        width: 280px;
      }
    }
  }
}