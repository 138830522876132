@import "src/styles/text";
@import "src/styles/colors";
@import "src/styles/table";

@keyframes highlightFade {
	0% {
	  background-color: rgba(0, 255, 0, 0.2);
	}
	100% {
	  background-color: transparent;
	}
}
  
.applicants {
	.filters-and-stats {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 24px;

		.filters-container {
			display: flex;
			gap: 16px;
			align-items: flex-start;
		}

		.stats-container {
			display: flex;
			align-items: center;
			gap: 16px;
			margin-left: 24px;

			.user-count {
				color: grey;
			}

			.export-button {
				padding: 8px 16px;
				cursor: pointer;
				text-decoration: underline;
				@include text-small-subtitle;
				display: flex;
				align-items: center;
				gap: 8px;
			}
		}
	}

	&-title {
		@include text-page-title;
		margin-bottom: 36px;
	}

	.navigation-tabs {
		margin-bottom: 32px;
	}

	&-search {
		input {
			width: calc(100% - 82px);
			margin-bottom: 18px;
		}
	}

	&-table {
		&-created-at {
			text-align: center;
			width: fit-content;
		}
		tr.highlight-row {
			animation: highlightFade 3s ease-out forwards;
		}
		&-first-interaction-cell {
			&-date {
				padding-left: 25px;
			}
				
			&-ago-text {
				margin-top: 4px;
			}
		}
	}

	@include table-border-radius;
	@include table-horizontal-borders;

	table {
		margin-top: 24px;
		width: 100%;

		tr {
			cursor: pointer;
		}

		tr th:first-child,
		tr td:first-child {
			padding-left: 24px;
		}

		th {
			padding: 8px;
			text-align: left;
			@include text-small-subtitle;
			font-weight: initial;
		}

		td {
			padding: 24px 8px;

			.progress-chiclet {
				margin: 0;
			}

			.progress-chiclet-container {
				margin-bottom: 0px;
			}
		}
	}
}
