@import "src/styles/text";
@import "src/styles/colors";

.notification {
  background: $color-rich-black;
  margin-bottom: 24px;
  position: relative;
  padding: 12px 52px 12px 12px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.20);


  &-content {
    display: inline-block;
    @include text-body;
    color: $color-snow-white;
    text-align: center;
    max-width: 100%;

    a {
      vertical-align: bottom;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      max-width: 90%;
      display: inline-block;
    }
  }

  &-close {
    position: absolute;
    top: 10px;
    right: 16px;
    cursor: pointer;
  }

  &-required {
    text-align: left;
    border: 1px solid $color-rich-black;
    background: rgba($color-dark-yellow, 0.2);

    &-content {
      color: $color-rich-black;
    }


    &-warning {
      margin-right: 6px;
      vertical-align: bottom;
    }
  }
}