@import "src/styles/colors";
@import "src/styles/text";

.applicant-profile {
  padding: 24px;

  &-header {
    margin-bottom: 24px;
  }

  &-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    .main-actions {
      display: flex;
      align-items: center;
      gap: 16px;

      .info {
        margin-left: 8px;
      }
    }

    .assignment-toggle {
      min-width: 200px;
    }
  }

  .navigation-tabs {
    margin-bottom: 40px;
  }

  &-content {
    .courses-content {
      .search {
        input {
          min-width: 450px;
        }
      }
    }
  }

  .dropdown {
    width: unset !important;

    .sorting {
      &-active {
        &-text {
          width: unset !important;
          vertical-align: -webkit-baseline-middle;
        }
      }
    }
  }
}