@import "src/styles/colors";
@import "src/styles/text";

.college-card {
  display: inline-block;
  margin-left: 16px;
  margin-right: 16px;
  width: calc(33% - 32px);
  margin-bottom: 52px;
  border: 1px solid rgba($color-rich-black, 0.5);

  &-logo {
    position: relative;
    width: 100%;
    padding-bottom: 75%;
    cursor: pointer;

    &-location {
      @include text-small-bold;
      padding: 7px 5px;
      background: rgba($color-turquoise, 0.05);
      color: #46719C;
      position: absolute;
      top: 12px;
      right: 12px;
    }

    &-image {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 50%;
    }
  }

  &-description {
    border-top: 1px solid rgba($color-rich-black, 0.5);
    background: rgba($color-rich-black, 0.05);
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;

    &-name {
      @include text-small-subtitle;
      margin-bottom: 12px;
    }

    &-courses {
      margin-bottom: 12px;

      .status-chiclet-container {
        margin: 0;
      }
    }

    &-programs {
      padding-bottom: 24px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .college-card {
    width: calc(100% - 32px);
  }
}