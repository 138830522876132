@import "../../../../styles/text";

.assessment-back {
  position: absolute;
  top: 105px;
  left: 56px;
  cursor: pointer;
  z-index: 1;

  &-icon {
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
  }

  &-text {
    display: inline-block;
    vertical-align: sub;
    padding-top: 6px;
    @include text-small;
  }
}