@import "src/styles/colors";
@import "src/styles/text";

.result-grading {
  width: 94px;
  height: 94px;
  padding: 11px;
  border-radius: 50%;
  position: relative;
  display: inline-block;

  &-border {
    position: absolute;
    border: 2px solid rgba($color-rich-black, 0.2);
    border-radius: 50%;

    &-outside {
      left: 0;
      top: 0;
      width: 112px;
      height: 112px;
    }

    &-inside {
      left: 9px;
      top: 9px;
      width: 94px;
      height: 94px;
    }
  }

  &-inside {
    width: 94px;
    height: 94px;
    background: $color-snow-white;
    border-radius: 50%;
    font-family: Castoro, monospace;
    font-size: 36px;
    color: $color-rich-black;
    text-align: center;
    line-height: 94px;
  }

  &-small {
    width: 42px;
    height: 42px;
    padding: 6px;

    .result-grading {
      &-inside {
        width: 42px;
        height: 42px;
        font-size: 16px;
        line-height: 42px;
      }

      &-border {
        border: 1px solid rgba($color-rich-black, 0.2);

        &-inside {
          width: 42px;
          height: 42px;
          left: 5px;
          top: 5px;
        }

        &-outside {
          width: 52px;
          height: 52px;
        }
      }
    }
  }
}