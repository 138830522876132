@import "src/styles/colors";
@import "src/styles/text";

.editable-score {
  margin-bottom: 10px;
  display: inline-block;
  width: 130px;
  text-align: center;

  &-title {
    margin-left: 14px;
    svg {
      margin-right: 8px;
      vertical-align: bottom;
    }

    @include text-small;
    margin-bottom: 12px;
    cursor: pointer;
  }

  .score {
    margin-left: 14px;
    margin-bottom: 18px;
  }

  &-edited-by {
    @include text-small;
    color: $color-green;
  }

  &-save {

  }
}