@import "src/styles/colors";
@import "src/styles/text";

.assessment-results {
  margin-top:24px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &-combination {
    margin-bottom: 24px;

    &-left {
      margin-right: 16px;
    }
  }

  &-accordion-grade {
    margin-bottom: 24px;
  }
  &-h1 {
    @include text-subtitle;
    margin-bottom: 8px;
  }

  &-h2 {
    @include text-small-subtitle;
    margin-bottom: 8px;
  }

  &-h3 {
    @include text-small-subtitle;
    margin-bottom: 8px;
  }

  &-title {
    @include text-page-title;
    max-width: 650px;
    width: 650px;
    margin-bottom: 24px;
    display: flex;
    margin-right: 60px;
    align-self: center;
  }

  &-text {
    width: 650px;
  }
  
  &-overall-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 650px;
    margin-bottom: 24px;
  }

  &-combination {
    width: 650px;
    display: flex;

    &-grading {
     margin-right: 16px; 
    }

    &-texts{
      margin-bottom: 24px;

      &-title {
        @include text-small-subtitle;
        margin-bottom: 8px;
      }
    }
  }
  &-ranking {
    @include text-small-subtitle;
    margin-bottom: 8px;
    margin-top: 24px;
  }

  &-desp {
    border-top: 1px solid rgba($color-rich-black, 50%);
    border-bottom: 1px solid rgba($color-rich-black, 50%);
  }
  &-grading {
    margin-bottom: 8px;
  }

  &-text {
    @include text-body;
    margin-bottom: 16px;
    max-width: 750px;

    &-border {
      border-top: 1px solid rgba($color-rich-black, 50%);
      
    }
  }
  
  &-accordion {
    display: flex;
    width: 750px;
    justify-content: space-between;
    @include text-small-subtitle;
    padding-top: 8px;
    padding-bottom: 8px;

    &-hidden {
      display: none;
    }
  }
}