@import "src/styles/text";

.quiz-container {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;

	.quiz-header {
		margin-bottom: 2rem;

		.back-button {
			background: none;
			border: none;
			color: #000;
			font-size: 1rem;
			cursor: pointer;
			padding: 0;
			display: flex;
			align-items: center;
			gap: 0.5rem;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.quiz-content {
		.quiz-title {
			@include text-page-small-title;
			margin-bottom: 1rem;

			@media (min-width: 768px) {
				font-size: 2.5rem;
			}
		}

		.quiz-info {
			@include text-subtitle;
			margin-bottom: 2rem;

			h2 {
				font-size: 1rem;
				font-weight: 600;
				margin-bottom: 0.5rem;

				@media (min-width: 768px) {
					font-size: 1.25rem;
				}
			}
		}

		.quiz-description {
			@include text-body;
			margin-bottom: 3rem;
			margin-top: 3rem;
		}
	}

	.quiz-section {
		background: #fff;
		border-radius: 8px;

		.questions-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 2rem;
			border-bottom: 1px solid #000;
			padding: 0px 40px 0px 20px;

			.questions-title {
				@include text-subtitle;
				margin-bottom: 1rem;

				@media (min-width: 768px) {
					font-size: 1.25rem;
				}
			}

			.save-button {
				margin-bottom: 1rem;
				background: none;
				border: none;
				color: #000;
				font-size: 1rem;
				font-weight: 600;
				// padding-right: 40px;
				display: flex;
				align-items: center;
				gap: 4px;

				&:disabled {
					color: #666;
				}
			}
		}

		.questions-list {
			display: flex;
			flex-direction: column;
			gap: 2rem;

			.question-item {
				.question-title {
					@include text-small-subtitle;
					margin-bottom: 1rem;
					line-height: 1.5;
					display: grid;
					grid-template-columns: auto 1fr;
					gap: 0.5rem;
					
					.question-number {
					  white-space: nowrap;
					}
				  }

				.word-count {
					text-align: right;
					color: #666;
					font-size: 0.875rem;
					margin-top: 0.5rem;
					padding-right: 0.5rem;

					@media (min-width: 768px) {
						padding-right: 40px;
					}
				}

				textarea {
					width: 95% !important;
					height: 150px !important;
					max-width: 100%;
					resize: vertical;
					min-height: 150px;

					@media (min-width: 768px) {
						height: 200px !important;
						margin-left: 15px;
					}
				}
			}
		}
	}

	.quiz-footer {
		margin-top: 2rem;

		@media (min-width: 768px) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		.submit-button {
			width: 100%;
			margin: 0;
			margin-top: 2rem;

			@media (min-width: 768px) {
				width: auto;
				margin-right: 30px;
				float: right;
			}
		}
	}
}
