@import "../../../styles/header";
@import "src/styles/text";
.header {
  @include header;
  
  @media (max-width: 768px) {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px;
    justify-content: space-between;

    &-logo {
      width: 250px;
      height: auto;
      padding-top: 0;
      margin-right: 110px;
    }

    &-sandwich {
      margin-right: 10px;
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    position: absolute;
    right: 24px;
    top: 12px;
    
    @media (max-width: 768px) {
      top: 4px;
    }
  }

  &-notifications {
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    align-items: center;
    position: relative;
    
    @media (max-width: 768px) {
      margin-right: 1px;
    }
    .notification-dot {
      position: absolute;
      top: 0;
      right: 0;
      width: 8px;
      height: 8px;
      background-color: #4CAF50;
      border-radius: 50%;
      border: 2px solid white;
    }
  }

  &-logo-fleming {
    cursor: pointer;
    width: 171px;
    position: absolute;
    left: 5px;
    top: 5px;
  }

  &-profile {
    display: inline-block;
    cursor: pointer;
    padding-bottom: 10px;
    @media (max-width: 768px) {
      padding-bottom: 0px;
    }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      right: 0;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 200px;
      padding: 8px 0;
      z-index: 100;
      
      @media (max-width: 768px) {
        top: 70%;
        left: -94px;
        width: 132px;
      }
    }

    .dropdown-item {
      padding: 8px 16px;
      cursor: pointer;
      transition: background-color 0.3s, border-radius 0.3s;
      border-radius: 10px;
      margin: 4px 8px;
      display: flex;
      align-items: center;
      @include text-body;
      
      &:hover {
        background-color: #ffef99;
      }
    }
  }

  .btn {
    margin-right: 24px;
  }

  &-start-over {
    &-icon {
      vertical-align: text-bottom;
      margin-right: 8px;
    }
  }

  .header-sandwich {
    display: none; 
    cursor: pointer;
    margin-right: 16px;
  }

  @media (max-width: 768px) {
    .header-sandwich {
      display: block;
    }
  }
}
