@import "src/styles/colors";
@import "src/styles/text";

.error-container {
  display: flex;
  margin-top: 4px;

  img {
    height: 26px;
    width: 26px;
    display: inline-block;
    margin-right: 8px;
  }

  &-text {
    @include text-small;
    color: $color-error-red;
    display: inline-block;
    vertical-align: top;
    margin-top: 5px;
  }
}