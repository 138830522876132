@import "../../../../styles/colors";
@import "../../../../styles/text";
@import "src/styles/resolution";
.assessment-question {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 650px;
  padding-top: 80px;
  height: 100%;
  min-height: 1100px;



  &-number {
    @include text-small;
    margin-bottom: 16px;
  }

  &-question {
    @include text-body;
    margin-bottom: 4px;
  }

  &-description {
    @include text-small-subtitle;
    margin-bottom: 24px;
  }

  &-text {
    @include text-body;
    margin-bottom: 4px;
  }

  &-image {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.assessment-container {
  width: 100%;
  position: relative;
  border-spacing: 0;

  &-content {
    padding-top: 80px;
    width: 50%;
    vertical-align: top;
    padding-right: 24px;
    padding-bottom: 24px;
    border-right: 1px solid rgba($color-rich-black, 0.5);
  }

  &-question {
    width: 50%;
    vertical-align: top;

    .assessment-question {
      left: 0;
      transform: translateX(0);
      padding-left: 23px;
    }
  }
  @media (max-width: $breakpoint-13inch) {
    height: 140vh;
  }
}