@import "src/styles/colors";
@import "src/styles/text";

.textarea {
  textarea {
    width: 309px;
    padding: 12px 16px;
    border-radius: 30px;
    border: 1px solid rgba($color-rich-black, 0.5);
    outline: inherit;

    @include text-body;
  }

  &-disabled {
    textarea {
      background-color: #f5f5f5;
      cursor: not-allowed;
      color: #666;
      border-color: #ddd;
      
      &:focus {
        border-color: #ddd;
        outline: none;
      }
    }
  }

  textarea::placeholder {
    @include text-body;
    color: rgba($color-rich-black, 0.6);
  }

  &-error {
    input {
      border: 1px solid $color-error-red;
      background: rgba($color-error-red, 0.1);
      margin-bottom: 4px;
    }
  }

  &-warning {
    height: 26px;

    img {
      height: 26px;
      width: 26px;
      display: inline-block;
      margin-right: 8px;
    }

    &-text {
      @include text-small;
      color: $color-error-red;
      display: inline-block;
      vertical-align: top;
      margin-top: 5px;
    }
  }
}