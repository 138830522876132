.search-and-sort {
  .search {
    display: inline-block;
    margin-right: 16px;
    width: calc(100% - 551px - 16px);

    input {
      width: -webkit-fill-available;
    }
  }

  .sorting {
    vertical-align: middle;
  }
}