@import "src/styles/text";
@import "src/styles/colors";

.applicant-course-details {

  .back {
    margin-bottom: 24px;
  }

  &-quiz {
    margin-bottom: 40px;
  }

  a {
    color: inherit;
  }

  &-title {
    @include text-page-title;
    margin-bottom: 24px;
  }

  &-code {
    @include text-small;
    margin-bottom: 4px;
  }

  &-mobile-header {
    display: none;
    min-height: unset!important;
    border-bottom: 1px solid rgba($color-rich-black, 0.3);
    margin-bottom: 28px;
  }

  table {
    width: 100%;
  }

  &-left {
    position: relative;
    width: 60%;
    vertical-align: top;

    &-subtitle {
      @include text-subtitle;
      margin-bottom: 16px;
      max-width: 650px;
    }

    &-text {
      @include text-body;
      margin-bottom: 16px;
      padding-right: 12px;
      max-width: 650px;

      ol {
        margin-top: 0;
      }
    }

    &-divider {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      border-radius: 1px;
      background: rgba($color-rich-black, 0.3);
    }

    &-mobile {
      display: none;
      @media (max-width: $breakpoint-mobile) {
        display: block;
      }
    }
  }

  &-right {
    width: 40%;
    padding-left: 24px;
    vertical-align: top;

    &-subtitle {
      @include text-subtitle;
      margin-bottom: 16px;
      max-width: 650px;
    }

    &-text {
      @include text-body;
      margin-bottom: 56px;
      max-width: 650px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .applicant-course-details-mobile-header {
    display: table;
  }
  .applicant-course-details-right {
    display: none;
  }

  .applicant-course-details-left-divider {
    display: none;
  }

  .applicant-course-details-right-text {
    margin-bottom: 22px;
  }
}