@import "../../styles/colors";

.layout {
  &-header {
    position: fixed;
    width: 100%;
    background: $color-snow-white;
    z-index: 100;
    top: 0;

    @media (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 16px;
    }

    .mobile-menu-toggle {
      display: none;
      cursor: pointer;
      z-index: 110;
    }
  }

  &-content {
    display: flex;
    margin-top: 71px;
    min-height: 95vh;
    @media (max-width: 768px) {
      margin-top: 51px;
    }

    &-menu {
      width: 231px;
      padding: 8px;
      transition: all 0.3s ease-in-out;
      position: relative;

      .menu-close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        z-index: 110;
      }

      @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: $color-snow-white;
        z-index: 105;
        transform: translateX(-100%);

        &.show {
          transform: translateX(0);
          width: 95%;
          top: 51px;
        }
      }
    }

    &-page {
      flex-grow: 1;
      width: 100%;
      padding: 24px;
      overflow: hidden;
      transition: width 0.3s ease-in-out;

      @media (max-width: 768px) {
        width: 100%;
        .layout.menu-open & {
          display: none;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .mobile-menu-toggle {
      display: block;
    }
  }
}
