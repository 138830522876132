@import "src/styles/colors";
@import "src/styles/text";

.evidence-submission {
    max-width: 100%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: black;

    &-content {
        padding: 10px 10px;
    }

    &-title {
        @include text-page-title;
    }
  
    .tabs {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      border-bottom: 1px solid #ddd;
      
      .tab-button {
        text-align: center;
        cursor: pointer;
        background: none;
        border: none;
        border-bottom: 3px solid transparent;
        transition: all 0.3s ease;
        font-size: 16px;
        color: black;
        position: relative;
  
        &.active {
          border-bottom: 3px solid #FFD700;
        }
      }
  
      .tab-separator {
        color: black;
        font-weight: normal;
      }
    }
  
    .tab-content {
      border-radius: 5px;
      background-color: #fff;
    }
  
    .submit-section {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      padding: 20px 0;
    }
  }