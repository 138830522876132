@import "src/styles/text";

.my-profile {
  &-title {
    @include text-subtitle;
    margin-top: 42px;
    margin-bottom: 24px;
  }

  &-footer {
    margin-top: 56px;
    margin-bottom: 24px;
  }
}