@import "src/styles/colors";
@import "src/styles/text";

.question-result {
  margin-bottom: 100px;

  &-left {
    display: inline-block;
    width: calc(100% - 150px);
    vertical-align: top;

    &-question {
      @include text-small-subtitle;
      margin-bottom: 20px;
    }

    &-answer {
      @include text-body;
    }
  }

  &-right {
    display: inline-block;
    width: 150px;
    vertical-align: top;
  }

  &-ai {
    margin-bottom: 20px;

    img {
      vertical-align: middle;
      margin-right: 10px;
    }

    &-text {
      @include text-small;
      color: $color-error-red;
    }
  }

  &-note {
    &-header {
      margin-bottom: 18px;

      &-title {
        @include text-small-subtitle;
        display: inline-block;
        width: 50%;
      }

      &-edit {
        text-align: right;
        display: inline-block;
        width: calc(50% - 20px);
        padding-right: 20px;

        svg {
          margin-right: 8px;
          vertical-align: bottom;
        }

        @include text-small;
        cursor: pointer;
      }
    }

    &-field {
      &-text {
        border: 1px solid rgba($color-rich-black, 0.5);
        border-radius: 10px;
        @include text-body;
        padding: 9px 18px;
        margin-right: 20px;
        min-height: 24px;
      }

      textarea {
        width: calc(100% - 56px);
        margin-bottom: 26px;
        height: 96px;
        @include text-body;
        resize: none;
        border-radius: 10px;
        padding: 9px 18px;
        border: 1px solid rgba($color-rich-black, 0.5);
      }
    }

    &-footer {
      text-align: right;
      margin-right: 20px;

      .btn {
        margin-left: 8px;
      }
    }
  }

}