@import "../../../styles/header";
@import "src/styles/text";

.kmq-header {
  @include header;
  border-bottom: none;
  padding: 20px;

  @media (max-width: $breakpoint-mobile) {
    &-logo {
      width: 50%;
    }
  }
}
