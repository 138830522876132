@import "../../../../styles/colors";
@import "../../../../styles/text";

.assessment-intro {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 650px;
  padding-top: 80px;
  padding-bottom: 110px;

  &-title {
    @include text-page-title;
    margin-bottom: 24px;
  }

  &-details {
    margin-bottom: 24px;
    display: flex;
    justify-content: start;
    align-items: center;
    
    &-icon {
      margin-right: 8px;
    }
    &-text {
      margin-right: 16px;
    }
  }

  &-description {
    @include text-body;
    li {
      margin-bottom: 8px;
    }
  }
}