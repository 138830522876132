@import "src/styles/text";

.notifications-page {
  padding: 1rem 2rem 2rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  border: 1px solid #262626;
  border-radius: 10px;

  .notifications-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    h1 {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .dismiss-all {
      padding: 0.5rem 1rem;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      @include text-small-subtitle;
      color: #ffc107 !important;
    }
  }

  .notifications-page-list {
    margin: 0 -2rem;
    max-height: 600px;
    overflow-y: auto;

    .no-notifications {
      margin-top: 120px;
      padding: 24px 0px 0px 0px;
      text-align: center;
      color: #807f7f !important;
      @include text-body;
      border-top: 1px solid #cbcaca;
    }

    .notification-item {
      position: relative;
      padding: 1rem 2rem;
      border-bottom: 1px solid #cbcaca;
      display: flex;
      align-items: center;
      transition: background-color 500ms ease-out;

      &.unread {
        background-color: #fff9e6;
      }

      &.fade {
        background-color: transparent;
      }

      &::before {
        content: '';
        min-width: 8px;
        width: 8px;
        height: 8px;
        margin-right: 1rem;
        margin-top: 0;
        display: block;
        @media screen and (max-width: 768px) {
          min-width: 12px;
          width: 12px;
          height: 12px;
          margin-top: 0.4rem;
        }
      }

      .notification-dot {
        position: absolute;
        left: 2rem;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        background-color: #4CAF50;
        border-radius: 50%;
        @media screen and (max-width: 768px) {
          left: 1rem;
        }
      }

      .notification-contents {
        color: black;
        cursor: pointer;
        flex: 1;
        p {
          margin: 0 0 0.5rem 0;
        }
        .timestamp {
          color: #666;
          font-size: 0.875rem;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    border: none;
    border-radius: 0;
    .notifications-header {
      margin-bottom: 1.5rem;
      h1 {
        font-size: 1.25rem;
      }
    }
    .notifications-page-list {
      margin: 0 -1rem;
      max-height: 70vh;
      .notification-item {
        padding: 0.875rem 1rem;
      }
    }
  }
}