@import "src/styles/text";

.roi {
  &-body {
    max-width: 1101px;
    margin: 63px auto;
    padding: 0 53px;

    &-title {
      @include text-subtitle;
      font-size: 30px;
      margin-bottom: 40px;
    }

    &-subtitle {
      @include text-subtitle;
      margin-bottom: 28px;
    }

    &-text {
      @include text-body;
      margin-bottom: 28px;
    }

    &-formula {
      margin-top: 74px;
    }

    &-form {
      margin-top: 77px;

      &-inputs {
        display: inline-block;
        width: 49%;
        padding-right: 8px;
        vertical-align: top;

        &-container {
          margin-bottom: 24px;
          float: right;
        }
      }

      &-result {
        display: inline-block;
        width: calc(49% - 147px);
        padding-left: 147px;
        vertical-align: top;
        padding-top: 60px;

        &-container {
          margin-bottom: 13px;

          &-text {
            text-align: center;
            @include text-body;
            margin-bottom: 5px;
          }

          &-number {
            text-align: center;
            font-family: "Avenir Next Demi", monospace;
            font-size: 42px;
            font-weight: 500;
            color: #000;
          }
        }
      }
    }

  }
}