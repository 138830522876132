@import "../../../../../../styles/v2/text";
@import "../../../../../../styles/v2/colors";
@import "../../../../../../styles/v2/resolution";

.upload-resume {
  padding-top: 151px;
  width: 910px;
  margin: auto;
  text-align: center;

  @media (max-width: $breakpoint-tablet) {
    padding-top: 112px;
    width: calc(100% - 60px);
  }

  @media (max-width: $breakpoint-mobile) {
    padding-top: 81px;
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
  }

  &-header {
    margin-bottom: 20px;

    &-text {
      border-radius: 8px;
      padding: 6px 12px;
      background: $color-lightest-grey;
      white-space: pre;
      color: $color-dark-grey;

      @media (max-width: $breakpoint-mobile) {
        display: block !important;
      }

      &-title {
        @media (max-width: $breakpoint-mobile) {
          display: block;
          font-weight: 500;
          color: $color-black;
          margin-bottom: 2px;
        }
      }

      &-desc {
        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
      }

      &-mobile {
        display: none;

        @media (max-width: $breakpoint-mobile) {
          display: inline;
          color: $color-mid-grey;
        }
      }

    }
  }

  &-title {
    margin-bottom: 20px;
    color: $color-black;

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 10px;
    }
  }

  &-subtitle {
    margin-bottom: 20px;
    color: $color-dark-grey;
    padding-left: 25px;
    padding-right: 25px;
  }

  input {
    display: none;
  }

  &-box {
    width: calc(30vw - 60px);
    position: relative;
    margin: 50px auto 40px auto;
    padding: 230px 30px 30px 30px;
    border: 2px solid $color-blue;
    border-radius: 30px;
    box-shadow: 0 10px 40px -8px #1372FF4D;

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }

    &-ondrop {
      position: absolute;
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      border-radius: 30px;
      left: 3px;
      top: 3px;
      z-index: 10000;
      color: $color-blue;
      background: $color-white;

      h3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }
    }

    &-arrow-left {
      position: absolute;
      right: -185px;
      pointer-events: none;
    }

    &-arrow-right {
      position: absolute;
      left: -185px;
      pointer-events: none;
    }

    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    &-button {
      margin-bottom: 10px;
    }

    &-drag {
      color: $color-blue;
      margin-bottom: 26px;
    }

    &-paste {
      display: inline-block;
      color: $color-mid-grey;
      margin-bottom: 56px;

      &-button {
        display: inline-block;
        padding: 6px 8px;
        border-radius: 4px;
        background: $color-lightest-grey;
        margin-left: 6px;
        margin-right: 6px;
      }

      &-button:first-child {
        margin-left: 10px;
      }
    }

    &-file {
      margin-bottom: 11px;
      color: $color-mid-grey;
    }

    &-rules {
      color: $color-mid-grey;

      img {
        margin-right: 4px;
        vertical-align: middle;
      }

      &-item {
        margin-right: 22px;
      }

      &-item:last-child {
        margin-right: 0;
      }
    }
  }

  &-mobile-box {
    display: none;

    @media (max-width: $breakpoint-tablet) {
      display: block;
      margin-top: 40px;
      margin-bottom: 40px;

      .btn-v2 {
        width: 440px;
        margin-bottom: 10px;
      }
    }

    @media (max-width: $breakpoint-mobile) {
      margin-top: 20px;
      margin-bottom: 20px;

      .btn-v2 {
        width: 280px;
      }
    }
  }

  &-benefits {
    width: 125%;
    position: relative;
    left: -12.5%;

    margin-bottom: 40px;

    &-item {
      display: inline-block;
      min-width: 290px;
      margin-left: 10px;
      margin-right: 10px;
      text-align: center;
      color: $color-black;

      @media (max-width: $breakpoint-mobile) {
        min-width: unset !important;
        max-width: 100px;
      }

      &-desc {
        @media (max-width: $breakpoint-mobile) {
          text-align: center;
          display: block;
          min-width: unset !important;
        }
      }
    }
  }

  &-linkedin {
    margin-bottom: 20px;
    color: $color-mid-grey;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 15px;
    }
  }

  &-footer {
    .btn-v2:first-child {
      margin-right: 10px;

      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }

    @media (max-width: $breakpoint-mobile) {
      .btn-v2 {
        width: 100%;
      }
    }
  }
}