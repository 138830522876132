@import "../../../../../../styles/text";

.assessment-card-status {
  position: absolute;
  right: 24px;
  top: 24px;
  text-align: right;

  &-text {
    @include text-small;
    margin-bottom: 8px;
  }
}