@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

.need-help {
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 60px;
  padding: 60px;
  border-radius: 30px;
  background: $color-lightest-grey;

  @media (max-width: $breakpoint-tablet) {
    margin: 0;
    padding: 60px 30px;
    border-radius: 30px 30px 0 0;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 60px 20px;
  }

  &-title {
    margin-bottom: 30px;
    color: $color-black;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 20px;
    }

    @media (max-width: $breakpoint-mobile) {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  &-desc {
    margin-bottom: 40px;
    color: $color-dark-grey;

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 30px;
    }
  }

  &-body {
    display: flex;

    @media (max-width: $breakpoint-tablet) {
      display: block;
    }

    &-item {
      flex: 1;
      background: $color-white;
      padding: 40px;
      border-radius: 20px;

      @media (max-width: $breakpoint-mobile) {
        padding: 20px;
      }

      &:first-child {
        margin-right: 60px;

        @media (max-width: $breakpoint-tablet) {
          margin-right: 0;
          margin-bottom: 30px;
        }
      }

      &-title {
        margin-bottom: 20px;
        color: $color-black;

        img {
          vertical-align: text-bottom;
          margin-right: 15px;

          @media (max-width: $breakpoint-tablet) {
            vertical-align: middle;
          }
        }
      }

      &-desc {
        color: $color-dark-grey;
      }

      a {
        color: $color-dark-grey;
        text-decoration: none;
      }
    }
  }
}