@import "src/styles/colors";
@import "src/styles/text";

.quiz-results {
  &-title {
    @include text-page-title;
    margin-bottom: 36px;
  }

  &-overview {
    padding: 48px 16px;
    background: rgba($color-rich-black, 0.05);
    border-radius: 10px;
    margin-bottom: 84px;

    &-left {
      display: inline-block;
      width: 50%;
      vertical-align: top;

      &-title {
        @include text-subtitle;
        margin-bottom: 18px;
      }

      &-subtitle {
        @include text-subtitle;
        margin-bottom: 8px;
      }
    }

    &-right {
      display: inline-block;
      vertical-align: top;
      width: calc(50% - 40px);
      text-align: right;
      padding-right: 40px;

      &-title {
        @include text-subtitle;
        margin-bottom: 20px;
      }
    }
  }

  &-header {
    padding-bottom: 20px;
    border-bottom: 1px solid $color-rich-black;
    margin-bottom: 52px;

    &-left {
      @include text-subtitle;
      display: inline-block;
      width: calc(50% - 10px);
      padding-left: 10px;
      vertical-align: top;
    }

    &-rigth {
      @include text-subtitle;
      display: inline-block;
      vertical-align: top;
      width: calc(50% - 40px);
      text-align: right;
      padding-right: 40px;
    }
  }

  &-footer {
    text-align: right;
    margin-right: 20px;
  }
}