.submit-payment-button {
  margin-bottom: 24px;
  button {
    margin-right: 16px;
    vertical-align: top;

    svg {
      margin-right: 8px;
      vertical-align: top;
    }
  }
}