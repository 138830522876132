@import "src/styles/text";
@import "src/styles/colors";

.evidences {

  &-chiclets {
    .skill-chiclet {
      margin-right: 8px;
    }
  }

  &-code {
    @include text-small-subtitle;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  &-title {
    @include text-page-title;
    margin-bottom: 24px;
  }

  &-name {
    margin-bottom: 8px;
    @include text-subtitle;
  }

  &-description {
    margin-bottom: 24px;
    width: 100%;

    &-text {
      @include text-body;
      display: inline-block;
      max-width: calc(100% - 490px);
      margin-right: 60px;
    }

    &-buttons {
      display: inline-block;
      vertical-align: top;

      .evidence-button {
        margin-right: 12px;
      }
    }
  }

  &-buttons {
    position: relative;
    padding-bottom: 30px;
    border-bottom: 1px solid $color-rich-black;
    margin-bottom: 30px;

    &-option {
      margin-right: 48px;
      cursor: pointer;

      &-image {
        margin-right: 12px;
        vertical-align: sub;
      }

      &-text {
        @include text-subtitle;
        text-decoration: underline;
      }

      &-disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .btn {
      margin-right: 16px;
    }

    .checkbox {
      display: inline-flex;
      margin-bottom: 0;
      top: 10px;
      right: -168px; // 20px padding + 148px component width
      position: absolute;
    }
  }

  &-table {
    &-title {
      @include text-subtitle;
      margin-bottom: 24px;
    }

    &-select {
      .checkbox-text {
        @include text-small;
        padding-top: 6px;
      }
    }

    &-footer {
      float: right;

      .btn {
        margin-left: 16px;
      }
    }
  }
}