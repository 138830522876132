@import "src/styles/v2/resolution";
@import "src/styles/v2/colors";

.landing-page-upload {
  width: 60%;
  margin: 150px 60px 210px;

  @media (max-width: $breakpoint-tablet) {
    width: auto;
    margin: 90px 30px;
    border-radius: 30px;
    padding: 60px 30px;
    background: $color-lightest-grey;
    text-align: center;
  }

  @media (max-width: $breakpoint-mobile) {
    margin: 60px 10px;
    padding: 60px 20px;
  }

  &-text {
    margin-bottom: 22px;
  }

  &-extension {
    margin-bottom: 60px;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 40px;
    }
  }

  &-button {
    .btn-v2 {
      width: 30vw;

      @media (max-width: $breakpoint-tablet) {
        width: 440px;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 280px;
      }
    }
  }
}
