@font-face {
    font-family: 'Castoro';
    src: url("Castoro-Regular.ttf");
}

@font-face {
    font-family: 'Castoro Italic';
    src: url("Castoro-Italic.ttf");
}

@font-face {
    font-family: 'Avenir Next';
    src: url("AvenirNextLTPro-Regular.ttf");
}

@font-face {
    font-family: 'Avenir Next Demi';
    src: url("AvenirNextLTPro-Demi.otf");
}

@font-face {
    font-family: 'Avenir Next Bold';
    src: url("AvenirNextLTPro-Bold.ttf");
}
