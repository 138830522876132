@import "../../../../../styles/colors";
@import "../../../../../styles/text";
@import "../../../../../styles/resolution.scss";

.normalization-option {
    position: relative;
    border-radius: 30px;
    border: 1px solid #212121;
    margin-bottom: 24px;
    transition: all .25s ease-in-out;
    overflow: hidden;
    display: grid;

    &-title {
        &-active {
            margin-right: 16px;
            vertical-align: middle;
            cursor: pointer;
            position: relative;
            top: -1px;
        }

        @include text-body;
        padding-left: 24px;
        height: 64px;
        padding-right: 72px;
        width: calc(100% - 24px - 72px);
        line-height: 64px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;

        &-text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            flex: 1;
        }

        @media (max-width: $breakpoint-mobile) {
            display: flex;
            align-items: center;
        }

        &-menu-toggle {
            cursor: pointer;
            position: absolute;
            right: 24px;
            top: 20px;
        }
    }

    &-text {
        @include text-body;
        position: relative;
        top: -4px;
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 12px;

        &-title {
            display: none;
            font-weight: bold;
            margin-bottom: 8px;

            @media (max-width: $breakpoint-mobile) {
                display: block;
            }
        }
    }

    @media (max-width: $breakpoint-mobile) {
        line-height: 64px;
    }
}