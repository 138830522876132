.evidence-modal {

  &-close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
  }

  .section-header {
    display: none;
  }

  .info-section {
    margin-bottom: 0 !important;
  }

  .ReactModal {
    &__Content {
      padding: 0 !important;
      border-radius: 32px !important;
      height: fit-content!important;
    }
  }

}