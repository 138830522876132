@import "src/styles/v2/text";
@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

@import "src/styles/colors";
@import "src/styles/text";

.loading-modal {
  @include text-v2;

  .ReactModal {
    &__Content {
      box-shadow: 0 10px 30px 0 #00000040 !important;
      border-radius: 30px !important;
      width: 75%;
      height: 70vh;
      max-height: 536px;
      min-height: 350px;

      @media (max-width: $breakpoint-tablet) {
        width: calc(100vw - 120px - 64px) !important;
      }

      @media (max-width: $breakpoint-tablet) {
        width: 75% !important;
        margin: auto;
      }
    }

    &__Overlay {
      background-color: rgba($color-black, 0.6) !important;
      z-index: 10000000;
    }
  }

  &-logo {
    position: absolute;
    top: 56px;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
  }

  &-content {
    position: absolute;
    width: 82%;
    left: 9%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;

    &-text {
      margin-bottom: 30px;
    }

    &-bar {
      width: 100%;
      height: 8px;
      border-radius: 20px;
      background: $color-light-grey;
      margin-bottom: 10px;

      &-filled {
        height: 8px;
        border-radius: 20px;
        background: $color-blue;
        transition: width 0.6s ease-in-out;
      }
    }

    &-progress {
      color: $color-blue;
    }
  }

}


/*.loading-progress-bar-container {
  background-color: $color-white;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  &-content {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    width: 514px;
    background-color: $color-white;
    border: 1px solid #ddd;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    @media (max-width: 768px) {
      width: 350px;
    }

    .progress {
      width: 0;
      height: 10px;
      background-color: black;
      border-radius: 30px;
      transition: width 0.6s ease-in-out;
    }
  }

}
*/