@import "src/styles/colors";
@import "src/styles/text";

.input-container {
  position: relative;

  input {
    width: 309px;
    height: 22px;
    padding: 12px 16px;
    border-radius: 30px;
    border: 1px solid rgba($color-rich-black, 0.5);
    outline: inherit;

    @include text-body;
  }

  input::placeholder {
    @include text-body;
    color: rgba($color-rich-black, 0.6);
  }

  &-hasIcon {
    input {
      padding-left: 64px;
    }
  }

  &-icon {
    position: absolute;
    top: 10px;
    left: 24px;
  }

  &-error {
    input {
      border: 1px solid $color-error-red;
      background: rgba($color-error-red, 0.1);
    }
  }

  &-warning {
    height: 26px;

    img {
      height: 26px;
      width: 26px;
      display: inline-block;
      margin-right: 8px;
    }

    &-text {
      @include text-small;
      color: $color-error-red;
      display: inline-block;
      vertical-align: top;
      margin-top: 5px;
    }
  }

  &-password-icon {
    position: absolute;
    right: 16px;
    top: 33px;
    cursor: pointer;
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}