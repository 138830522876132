@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

.about-plar {
  padding: 150px 60px;

  @media (max-width: $breakpoint-tablet) {
    padding: 120px 30px;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 60px 20px;
  }

  &-title {
    margin-bottom: 60px;
    color: $color-black;

    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 40px;
    }

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 30px;
    }
  }

  &-desc {
    margin-bottom: 20px;
    color: $color-dark-grey;

    &-last {
      margin-bottom: 80px;

      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 50px;
      }
    }
  }

  &-affiliated {
    &-text {
      vertical-align: middle;
      display: inline-block;
      margin-right: 60px;
      color: $color-dark-grey;

      @media (max-width: $breakpoint-tablet) {
        display: block;
        margin-bottom: 20px;
      }

      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 10px;
      }
    }

    img {
      vertical-align: middle;
      margin-right: 60px;
      height: 36px;

      @media (max-width: $breakpoint-tablet) {
        height: 40px;
        margin-right: 50px;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 25%;
        height: auto;
        margin-right: 20px;
      }
    }
  }
}