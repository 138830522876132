@import "src/styles/colors";
@import "src/styles/text";
@import "src/styles/cards";
@import "src/styles/resolution";

.course-card {
  @include regular-card;
  height: 166px;
  width: calc(100% - 50px);

  .status-chiclet-container, .progress-chiclet-container {
    margin-left: 0;
  }

  .skill-chiclet {
    margin-left: 16px;
    background: $color-green;
  }

  &-code {
    @include text-small;
    margin-bottom: 8px;
  }

  &-status {
    position: absolute;
    right: 24px;
    top: 24px;
    text-align: right;
    @media (max-width: $breakpoint-mobile) {
      right: 10px;
    }

    .progress-chiclet-container {
      margin-left: 16px;
    }

    &-text {
      @include text-small;
      margin-bottom: 8px;
    }

    &-payment {
      display: inline-block;
      cursor: pointer;
      margin-right: 16px;

      img {
        position: relative;
        top: 2px;
        margin-right: 4px;
        vertical-align: text-bottom;
      }

      &-text {
        @include text-small-subtitle;
        @media (max-width: $breakpoint-mobile) {
          font-size: 14px;
        }
      }

      &-container {
        &-mobile {
          display: none;
        }
      }
    }
  }

  &-title {
    margin-bottom: 8px;
  }

  &-subtitle {
    @include text-small;
    margin-bottom: 8px;
  }

  &-footer {
    width: calc(100% - 48px);
    position: absolute;
    bottom: 24px;
    right: 24px;

    &-labels {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
    }

    &-warning {
      display: inline-block;

      img {
        margin-right: 6px;
        vertical-align: bottom;
      }

      &-text {
        @include text-small;
        color: $color-error-red;
      }
    }

    &-buttons {
      text-align: right;
    }

    &-due {
      @include text-small;
      font-size: 16px;
      margin-right: 20px;

      &-dot {
        border-radius: 50%;
        width: 9px;
        height: 9px;
        background: $color-green;
        display: inline-block;
        margin-right: 6px;
      }
    }

    .btn {
      margin-left: 16px;
      @media (max-width: $breakpoint-mobile) {
        font-size: 14px;
      }
    }
  }

  &-green {
    background: rgba($color-green, 0.2);
  }

  &-yellow {
    background: rgba($color-dark-yellow, 0.2);
  }

  &-red {
    background: rgba($color-error-red, 0.1);
  }

  &-white {
    background: $color-snow-white;
  }
}

@media (max-width: $breakpoint-mobile) {
  .course-card {
    height: 215px;

    .progress-chiclet-container {
      margin-bottom: 8px;
    }

    &-status {
      &-nocpl {
        position: unset;
        left: 0;
        right: 10px;
        margin-left: 0;
        text-align: left;

        .skill-chiclet {
          margin-left: 0;
          margin-bottom: 12px;
        }
      }
    }

    &-footer {
      bottom: 16px;
    }
  }

  .course-card-status-payment-container {
    display: none;

    &-mobile {
      display: inline-block;
      position: absolute;
      left: 24px;
      bottom: 28px;
    }
  }
}