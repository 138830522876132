@import "src/styles/colors";
@import "src/styles/text";

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .pagination-button {
    background-color: $color-snow-white;
    border: 2px solid $color-rich-black;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px;
    cursor: pointer;
    @include text-body;

    &:hover {
      background-color: #ddd;
    }
  }

  .pagination-button.active {
    background-color: $color-rich-black;
    color: $color-snow-white;
  }

  .pagination-arrow {
    margin: 0 4px;
    cursor: pointer;
  }

  .pagination-arrow.disabled {
    cursor: not-allowed;
  }

  .pagination-button span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .start-ellipsis {
    @include text-body;
    margin: 0 4px;
  }
}
