@import "../../../../../styles/text";

.education {
  position: relative;

  &-error {
    margin-bottom: 20px;
  }

  .textarea {
    textarea {
      width: calc(100% - 32px) !important;
      margin-bottom: 24px;
    }
  }

  .input-container {
    input, textarea {
      width: calc(100% - 32px) !important;
      margin-bottom: 24px;
    }
  }

  &-close {
    position: absolute;
    top: -16px;
    right: 16px;

    &-delete-icon {
      width: 75px;
      @include text-small;
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;

      &-disabled {
        color: rgba($color-rich-black, 0.5);
        cursor: not-allowed;
      }
    }
  }

  &-notification {
    margin-bottom: 24px;
    padding: 8px 16px 8px 16px;
    background: #FCF1CC;
    border: 1px solid #212122;
    border-radius: 10px;
    @include text-body;

    svg {
      margin-right: 6px;
      vertical-align: top;
    }
  }

  .select-container {
    width: 100%;
    margin-bottom: 24px;

    @media (max-width: 768px) {
      width: 100% !important;
    }

    @media (max-width: 576px) {
      width: 100% !important;
    }

    .selectkmq__control {
      width: 100%;
    }
  }
}