@import "src/styles/colors";
@import "src/styles/text";
@import "src/styles/resolution";

.sign-up {
  position: relative;
  height: 100%;
  
  &-content {
    max-width: 343px;
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translateX(-50%);

    &-nav {
      width: 200%;
      position: relative;
      left: -50%;
      text-align: center;
      margin-bottom: 84px;
      
      &-prev {
        @include text-small-subtitle;
        vertical-align: middle;
        margin-right: 12px;
      }
      
      &-next {
        @include text-small;
        font-size: 16px;
        line-height: 22px;
        vertical-align: middle;
      }
      
      &-icon {
        vertical-align: middle;
        margin-right: 12px;
      }
    }

    .notification {
      margin-top: 44px;
    }
  }

  &-title {
    @include text-page-title;
    margin-bottom: 24px;
  }

  .input-container {
    margin-bottom: 24px;
  }

  .checkbox-text {
    @include text-small;
    color: rgba($color-rich-black, 0.6);
  }

  &-continue {
    margin-bottom: 44px;

    .btn {
      width: 100%;
    }
  }

  &-or {
    position: relative;
    text-align: center;
    margin-bottom: 30px;

    &-text {
      display: inline-block;
      padding: 0 16px;
      text-transform: uppercase;
      @include text-small-subtitle;
      color: rgba($color-rich-black, 0.5);
      background: $color-snow-white;
      z-index: 1;
    }

    &-line {
      z-index: -1;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      width: 100%;
      background: rgba($color-rich-black, 0.3);
    }
  }

  &-login {
    margin-bottom: 40px;
    text-align: center;
    @include text-small;

    &-link {
      font-weight: bold;
      cursor: pointer;
    }
  }
}

/* Mobile Responsive Styles */
@media (max-width: $breakpoint-mobile) {
  .sign-up {
    &-content {
      position: relative;
      width: 100%;
      padding: 0 16px;
      top: 20px;

      &-nav {
        width: 100%;
        left: 0;
        margin-bottom: 40px;

        &-prev {
          font-size: 14px;
          margin-right: 8px;
        }

        &-next {
          font-size: 14px;
          line-height: 20px;
        }

        &-icon {
          width: 16px;
          margin-right: 8px;
        }
      }

      .notification {
        margin-top: 24px;
      }
    }

    &-title {
      font-size: 24px;
      margin-bottom: 16px;
    }

    .input-container {
      margin-bottom: 16px;
    }

    .checkbox-text {
      font-size: 12px;
      line-height: 16px;
    }

    &-continue {
      margin-bottom: 32px;
    }

    &-or {
      margin-bottom: 24px;

      &-text {
        font-size: 12px;
        padding: 0 12px;
      }
    }

    &-login {
      margin-bottom: 32px;
      font-size: 14px;
    }
  }
}