@import "src/styles/colors";
@import "src/styles/text";

.unsubscribe {
  background: #F5F5F5;
  min-height: 100vh;

  .login-header {
    border-bottom: 1px solid rgba($color-rich-black, 0.5);
    background: $color-snow-white;
  }

  &-title {
    @include text-body;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 32px;
    font-size: 22px;

    &-link {
      @include text-subtitle;
      text-decoration: underline;
      cursor: pointer;
      font-size: 22px;
    }
  }

  &-body {
    background: $color-snow-white;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 40px;
    padding: 40px 32px;
    border-radius: 24px;
    border: 1px solid #D4D4D4;

    &-title {
      @include text-subtitle;
      margin-bottom: 22px;
    }

    &-text {
      @include text-body;
      margin-bottom: 30px;
    }

    &-footer {
      text-align: right;
      margin-top: 56px;

      .btn {
        min-width: 156px;
      }
    }
  }
}