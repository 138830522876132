@import "src/styles/text";
@import "src/styles/colors";

.marketplace-header {
  position: relative;
  left: -24px;
  top: -24px;
  width: calc(100% + 48px);

  &-title {
    position: relative;
    width: 100%;
    height: 363px;

    &-text {
      position: absolute;
      left: 118px;
      top: 50%;
      transform: translateY(-50%);

      &-name {
        @include text-page-title;
        margin-bottom: 10px;
      }

      &-description {
        @include text-body;
        max-width: 440px;
      }
    }

    &-background {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &-description {
    border-top: 1px solid rgba($color-rich-black, 0.5);
    border-bottom: 1px solid rgba($color-rich-black, 0.5);
    background: rgba($color-rich-black, 0.03);
    padding-left: 100px;
    padding-right: 100px;

    &-item {
      margin-top: 24px;
      margin-bottom: 24px;
      display: inline-block;
      width: 33.33%;
      text-align: center;

      &-icon {
        margin-right: 24px;
      }

      &-text {
        max-width: 152px;
        display: inline-block;
        text-align: left;

        &-title {
          @include text-small-bold;
          font-weight: bold;
          margin-bottom: 2px;
        }

        &-description {
          @include text-small;
          color: rgba($color-rich-black, 0.5);
        }
      }
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .marketplace-header {
    &-title {
      height: 210px;

      &-text {
        left: 24px;
      }

      &-background {
        display: none;
      }
    }

    &-description {
      text-align: center;

      &-item {
        display: block;
        width: 100%;

        &-icon {
          margin-bottom: 8px;
          margin-right: 0;
        }

        &-text {
          text-align: center;
        }
      }
    }
  }
}