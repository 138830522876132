@import "src/styles/colors";
@import "src/styles/text";

.rejectdetails {
  @include text-body;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .checkbox-text {
    margin-left: 12px;
  }

  &-title {
    @include text-page-small-title;
    width: 795px;
    display: flex;
    align-self: start;
    margin-bottom: 24px;
  }

  &-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;


    &-checkbox {
      width: 100%;

      .textarea textarea {
        width: 100% !important;
        height: 162px !important;
      }

      &-sub {
        padding: 0;
        list-style: none;
      }
    }

    &-submit {
      margin-top: 32px;
      display: flex;
      align-self: start;
    }
  }
}