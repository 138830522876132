.employee-table {
  table {
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;

    td, th {
      text-align: left;
      border-bottom: 1px solid black;
      padding: 15px 8px;
      background-color: #ffffff;

      .table-name-cell {
        display: flex;
        flex-direction: row;
        align-items: center;

        .table-name-cell-job {
          font-family: Castoro, monospace;
          font-size: 14px;
        }
      }
    }

    th {
      padding-left: 18px;
      background-color: #ffffff;
    }

    tr:last-child td {
      border-bottom: none;
    }

    th:first-child, td:first-child {
      border-left-width: 1px;
    }

    th:last-child, td:last-child {
      border-right-width: 1px;
    }

    tbody {
      cursor: pointer;
      tr:nth-child(even) {
        background-color: #ffffff;
      }
    }
  }
  .search-sort-button {
    display: flex;
    flex-direction: row;
    gap: 300px;

    .add-employee-btn {
      display: flex;
      cursor: pointer;
      text-decoration: underline;
      gap: 6px;
    }
    .complex-search .sorting {
      width: 300px;
    }
  }
}
