@import "src/styles/colors";
@import "src/styles/text";
@import "src/styles/resolution";

.menu {
  position: relative;
  // min-height = full height - header - padding
  min-height: calc(100vh - 72px - 32px);
  height: 100%;
  width: 224px;
  border-right: 1px solid rgba($color-rich-black, 0.5);
  padding: 8px 8px 25px;
  @include text-body;

  @media (max-width: $breakpoint-mobile) {
    height: 80vh;
  }

  &-footer {
    transition: all 0s;
    position: fixed;
    bottom: 8px;
    left: 8px;

    @media (max-width: $breakpoint-mobile) {
      position: absolute;
      bottom: 8px !important;
    }

    &-version {
      padding-left: 16px;
      @include text-small;
    }
  }
}