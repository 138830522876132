@import "src/styles/v2/colors";
@import "src/styles/v2/resolution";

.college-process {
  margin-top: 90px;
  padding: 0 60px 120px 60px;
  border-radius: 30px;
  background: $color-black;
  overflow: hidden;


  @media (max-width: $breakpoint-tablet) {
    padding: 0 30px 90px 30px;
  }

  @media (max-width: $breakpoint-mobile) {
    margin-top: 30px;
    padding: 0 20px 60px 20px;
  }

  &-image {
    width: 100vw;
    position: relative;
    left: -60px;
    margin-bottom: 120px;

    @media (max-width: $breakpoint-tablet) {
      left: -40px;
      margin-bottom: 90px;
    }

    @media (max-width: $breakpoint-mobile) {
      left: -30px;
      margin-bottom: 40px;
    }
  }

  &-title {
    margin-bottom: 30px;
    color: $color-white;
    max-width: 75%;

    @media (max-width: $breakpoint-tablet) {
      max-width: 100%;
    }
  }

  &-desc {
    margin-bottom: 120px;
    color: $color-light-grey;
    max-width: 75%;

    @media (max-width: $breakpoint-tablet) {
      max-width: 100%;
      padding-bottom: 60px;
      border-bottom: 1px solid $color-dark-grey;
      margin-bottom: 60px;
    }

    @media (max-width: $breakpoint-mobile) {
      padding-bottom: 40px;
      margin-bottom: 40px;
    }
  }

  &-subtitle {
    text-align: center;
    margin-bottom: 50px;
    color: $color-mid-grey;

    @media (max-width: $breakpoint-tablet) {
      text-align: left;
      margin-bottom: 40px;
    }

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 30px;
    }
  }

  &-list {
    display: flex;

    @media (max-width: $breakpoint-tablet) {
      display: block;
    }

    &-item {
      flex: 1;
      margin-right: 30px;
      color: $color-light-grey;

      @media (max-width: $breakpoint-tablet) {
        margin-right: 0;
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &-title {
        color: $color-white;
        white-space: pre;
        margin-bottom: 10px;
      }
    }
  }
}